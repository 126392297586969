export enum ERoles {
  User = 'user',
  Customer = 'client',
}

export enum ETimers {
  Short = 1500,
  Medium = 3000,
  Long = 7000,
  XLong = 10000,
}

export enum EColours {
  Blue = '#55BADE',
  Violet = '#4A148C',
  LavenderBlue = '#7B61FF',
  Orange = '#F1AB50',
  Green = '#7CBA73',
  Yellow = '#FFCB2F',
  Grey = '#9B9B9B',
  Red = '#DB5F59',
  Black = '#000000',

  // map colours
  Orchid = '#D15AD3',
  Carrot = '#FE9F3B',
  VioletBlue = '#826CF3',
  Pink = '#F03586',
  Emerald = '#4CC260',
  ElectricBlue = '#0493D9',
  Terracotta = '#D3615A',
  Teal = '#4CADC2',
  Olive = '#A8C338',
  Brown = '#B16C45',
  White = '#FFFFFF',
}

// Should be synced with 'styles.scss' classes
export enum ETextClasses {
  Grey = 'grey',
  Green = 'green',
  Orange = 'orange',
  Red = 'red',
  Violet = 'violet',
  LavenderBlue = 'lavender-blue',
  Blue = 'blue',
  Black = 'black',
}

export enum EDays {
  'Monday' = 0,
  'Tuesday' = 1,
  'Wednesday' = 2,
  'Thursday' = 3,
  'Friday' = 4,
  'Saturday' = 5,
  'Sunday' = 6,
}

export enum EModuleIDs {
  Dashboard = 'dashboard',
  LiveMap = 'live_map',
  Orders = 'orders',
  OrdersAllocation = 'order_alloc',
  Manifests = 'ordermanifests',
  Vehicles = 'vehicles',
  Customers = 'clients',
  Drivers = 'drivers',
  Timetables = 'deliverey_tt',
  Zones = 'zones',
  Accounts = 'customers',
  Users = 'users',
  Reports = 'reports',
  Settings = 'settings',
  SafetyReport = 'safety_report',
  SafetyPlans = 'safety_plans',
  SafetySettings = 'safety_settings',
  Analytics = 'analytics',
  AllFeedback = 'all_feedback',
  ManageQrCodes = 'manage_qr_codes',
  WorkspacePlans = 'workspace_plans',
}

export enum EModuleExtIDs {
  Safety = 'safetyweb',
  SafetyRepair = 'safetyrepair',
}

export enum EModuleURLs {
  Dashboard = 'dashboard',
  LiveMap = 'live-map',
  Orders = 'orders',
  OrdersAllocation = 'orders_allocation',
  Manifests = 'manifests',
  Vehicles = 'vehicles',
  Customers = 'customers',
  Drivers = 'drivers',
  Timetables = 'timetables',
  Zones = 'zones',
  Accounts = 'accounts',
  Users = 'users',
  Reports = 'reports',
  Settings = 'settings',
  SafetyReport = 'safety_management/report',
  SafetyPlans = 'safety_management/plans',
  SafetySettings = 'safety_management/settings',
  Analytics = 'analytics',
  AllFeedback = 'all-feedback',
  ManageQrCodes = 'manage_qr_codes',
  WorkspacePlans = 'workspace-plans',
}

export enum EReportTypes {
  DeliveredItems = 'DeliveredItems',
  DeliveredOrders = 'DeliveredOrders',
  AllOrders = 'AllOrders',
  ManifestItems = 'ManifestItems',
  TareTracking = 'TareTracking',
  Label = "Label",
  XDocConNoteReport = 'XDocConNoteReport',
}

export enum EWizardTooltips {
  CompanyName = 'This will be displayed on reports and Proof of Delivery documents.',
  CompanyLogo = 'Add your company logo for branded customer notifications. This will also be displayed on Proof of Delivery documents.',
}

export enum ESignupTooltips {
  YourName = 'Your full name, as responsible for this account.',
  AccountName = 'This name uniquely identifies your account.',
  CompanyEmail = 'We will be sending a verification email to this address.',
  CompanyPhone = 'A valid mobile or fixed phone number, for the company.',
}
export enum EIconsTooltips {
  Returns = 'At least 1 item returned.'
}
export enum ESettingsTooltips {
  CommentsRequired = 'Orders cannot be completed without a comment. This can be overridden for individual manifests or orders.',
  SignaturesRequired = 'Orders cannot be completed without a signature. This can be overridden for individual manifests or orders.',
  PhotosRequired = 'Orders cannot be completed without at least one photo. This can be overridden for individual manifests or orders.',
  DeliveryLoadsRequired = 'Orders cannot be completed without delivery load information. This can be overridden for individual manifests or orders.',
  PickupLoadsRequired = 'Orders cannot be completed without pickup load information. This can be overridden for individual manifests or orders.',
  Timezone = 'The timezone is used for accurate Pickups, Deliveries and Route Optimisation. Will be overridden by Client timezone if applicable.',
  Gst = 'Goods and Services Tax percentage.',
  UseSystemDefaultTimes = 'Use system (or client) default times for pickup and delivery, when importing orders.',
  RetainImportedAddresses = 'When importing orders, copy addresses to the order details field, prior to geocoding.',
  GeocodeWithName = 'When geocoding, include the client name (as well as the address) to make the search more specific.',
  GenerateManifestSummariesAsPDF = 'Generate manifest summaries as PDF files, rather than as Excel files.',
  UseCustomerReferenceInConsPDFs = 'In the Consignment Note PDF documents (Pickup / Delivery), use the Customer Reference rather than the SolBox order Id.',
  HideOrdersOlderThan = 'Limit the orders and manifests, that are shown, to the selected number of days.',
  IgnoreTimeForOrders = 'Don’t use pickup/delivery times for orders when creating or optimising manifests. Only use locations, and schedule the orders accordingly.',
  VehicleStartsEmpty = 'By default, the vehicle is assumed to be pre-loaded at the start of a manifest run.',
  AllocateToZonalManifests = 'If the pickup/delivery location falls inside a defined zone, allocate the order during import to a specific manifest (zone and date).',
  zonalManifestDateLookahead = 'If an order matches a Zone that is restricted to specific weeks / days, then change the order date to the first valid date for the Zone.',
  clearUnallocatedOrderDates = 'During import of orders, if an order cannot be allocated to a Manifest, remove the Order Pickup/Delivery date and time.',
  EnableLoadTracking = 'Enables the tallying of configured load types based on Load Options to either add or subtract a load value which can be applied at Pickup, Delivery, Pickup-Delivery or ALL.',
  ShiftLength = 'Shifts shorter than this don’t get a break.',
  DynamicAllocation = 'Orders can be dynamically inserted into existing manifests, based on the specified parameters.',
  MaxCalculationTime = 'The time spent on searching for an optimal solution.',
  AllowWaitTime = 'The time that the driver can spend waiting between pickups/deliveries.',
  MaxDrivingTime = 'The total time spent driving from the start to the end of a given route (for a given driver).',
  StopAfterFirstSolution = 'Don’t keep trying to find more optimal solutions once one has been found.',
  HideSolutionsWithDroppedOrders = 'If the order allocation requires any existing orders to be dropped from a manifest, then ignore this solution and look for another.',
  EnableBarcodeTracking = 'Enable barcode creation and tagging on loads for scan on and scan off of tracking.',
  OwnCustomQRCodes = 'Important! If you decide to use your own QR-codes your drivers can only be redirected within the app scanner.',
  ManifestRealTimeRoutesEnabled = 'The option to enable or disable real-time routes for drivers, manifests, and live maps is available.'
}

export enum EDriverTooltips {
  Pin = 'Live mobile app drivers access pin.',
  Timetable = 'The timetable for specifying the drivers availability.',
  PreferredVehicle = 'The drivers default vehicle, if any.',
}

export enum ECustomerTooltips {
  Timezone = 'The timezone is used for accurate Pickups, Deliveries and Route Optimisation. If this is empty, the Account timezone will be used instead.',
  EmailsAndPhones = 'ETA and Order Status Notification Recipients, add multiple separated with ";".',
  Zone = 'The zone grouping that the customer is grouped to.',
  ManualAdjustment = 'Allows unit values entries to correct an imbalance.',
}

export enum EAccountTooltips {
  CapacityType = 'The capacity type that will be used for all orders and vehicles on the account.',
  MapServer = 'The server to retrieve maps from, and to determine regional parameters for geo-location and routing.',
  ConsignmentNote = 'The template which will be used for generating consignment notes.',
  ParentAccount = 'Administrators for the parent account will be able to manage this account.',
  Timezone = 'The timezone is used for accurate Pickups, Deliveries and Route Optimisation.',
  AccountLimited = 'This account is limited and will expire on the specified date.',
  InTrialPeriod = 'This account is in a Trial Period, and will expire if not upgraded before the trial period ends.',
}

export enum EVehicleTooltips {
  OutOfService = 'The vehicle will be unavailable for inclusion in optimisation or allocation.',
  MaxCapacity = 'The maximum capacity for the vehicle to be used in Optimisation. This can be any unit of measure (UOM) which must be consistent with the Order capacity UOM eg. Weight / Volume / People / Cartons etc.',
  MaxLength = 'The maximum length of loads that can be transported in this vehicle.',
  MobileDeviceSerialNumber = 'The serial number found displayed in settings of the Live mobile app.',
  LastOnlineLegend = 'Green: less than 5 mins\nYellow: 5 mins - 8 hours\nGrey: more than 8 hours',
}

export enum ELiveMapTooltips {
  SomeOrdersAfterMidnight = 'Some orders will be started/delivered after 12 am. We can\'t display them on the timeline.',
}

export enum EVehicleInspectionStatus {
  OK = 1,
  DueOrMissed = 2,
  DueOrMissedAndDriven = 3,
  CriticalFailure = 4,
  CriticalFailureAndDriven = 5,
  Failure = 6,
  FailureAndDriven = 7,
}

export enum EOrderStatuses {
  Pending = 0,
  EnrouteForDelivery = 1,
  Delivered = 2,
  EnrouteForPickup = 3,
  PickedUp = 4,
  Cancelled = 5,
}

export enum EOrderTypes {
  Delivery = 1,
  Pickup = 2,
  Service = 3,
  PickupDelivery = 4,
}

export enum EOrderFiltersSets {
  Unassigned = 1,
  Pending = 2,
  Enroute = 3,
  Cancelled = 4
}

export enum EOrderPaymentType {
  Cash = 0,
  Card = 1,
}

export enum EOrderDefaultLoadOption {
  Anywhere = -1,
}

export enum EUserPermissions {
  Forbidden = 0,
  ReadOnly = 1,
  WriteAccess = 2,
  FullAccess = 3,
}

export enum ECookies {
  OrdersGetParameters = 'ordersGetParameters',
  RouteOptimizationOptions = 'routeOptimizationOptions',
  UserName = 'userName',
  AccountName = 'accountName',
  Locale = 'locale',
  Role = 'role',
  MapType = 'mapType',
  WelcomeTourState = 'welcomeTourState',
}

export enum EOrderImportOptions {
  JIWA = 'JIWA',
  MYOB = 'MYOB',
  Shopify = 'Shopify',
  Woodwards = 'Woodwards',
  Accredo = 'Accredo',
  WooCommerce = 'WooCommerce',
  OrderMentum = 'OrderMentum',
  Dynamics = 'Dynamics',
  Fresho = 'Fresho',
}

export enum EVehicleImportOptions {
  MiX = 'MiX',
}

export enum EDriverImportOptions {
  MiX = 'MiX',
}

export enum EDatePresets {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Tomorrow = 'Tomorrow',
  ThisWeek = 'ThisWeek',
  LastWeek = 'LastWeek',
  LastSevenDays = 'LastSevenDays',
  LastThirtyDays = 'LastThirtyDays',
  ThisMonth = 'ThisMonth',
  PreviousMonth = 'PreviousMonth',
}

export enum ECustomIcons {
  Xls = 'xls',
  Pdf = 'pdf',
  Pause = 'pause',
  Play = 'play',
  Check = 'check',
  Cancel = 'cancel',
  TruckDelivery = 'truck_delivery',
  TruckPickup = 'truck_pickup',
  Link = 'link',
  Pickup = 'pickup',
  Delivery = 'delivery',
  PickupDelivery = 'pickup_delivery',
  PickupDeliveryDeliveryNode = 'pickup_delivery_d_node',
  PickupDeliveryPickupNode = 'pickup_delivery_p_node',
  Service = 'service',
  Truck = 'truck',
  Distance = 'distance',
  Depot = 'depot',
  Image = 'image',
  QR = 'qr_code',
  PieChart = 'pie_chart',
  Device = 'device',
  WarningCircleFilled = 'warning_circle_filled',
  WarningCircle = 'warning_circle',
  FailureCircleFilled = 'failure_circle_filled',
  FailureCircle = 'failure_circle',
  HandPalm = 'hand_palm',
  Pickup2 = 'pickup_2',
  Delivery2 = 'delivery_2',
  PickupDeliveryPickup2 = 'pickup_delivery_pickup_2',
  PickupDeliveryDelivery2 = 'pickup_delivery_delivery_2',
  PickupDelivery2 = 'pickup_delivery_2',
  Service2 = 'service_2',
  Service3 = 'service_3',
  WarningRounded = 'warning_rounded',
  ArrowUpCircle = 'arrow_up_circle',
}

export enum ETimes {
  Second = 1000,
  Minute = 60 * 1000,
  Hour = 60 * 60 * 1000,
  Day = 24 * 60 * 60 * 1000,
}

export enum EManifestStatuses {
  Pending = 0, // All orders are either Pending or Cancelled - there has to be at least one Pending order
  InProgress = 1, // At least one order, but not all, is In Progress or Completed
  Completed = 2, // All orders are either Completed (at least 1) or Cancelled
  Cancelled = 3, // All orders on the manifest are Cancelled
}

export enum EMapTypes {
  Hybrid = 'hybrid',
  Default = 'default',
  Traffic = 'traffic',
}

export enum EOnboardingSteps {
  CompanyInfo = 'company_info',
  Vehicles = 'vehicles',
  Drivers = 'drivers',
}

export enum EWelcomeTourSteps {
  Default,
  Inactive,
  Start,
  DashboardInit,
  NewOrderDelivery,
  NewOrderPayment,
  NewOrderDetails,
  NewOrderSave,
  DashboardCreateMoreOrders,
  DashboardOrdersCreated,
  DashboardSelectOrders,
  DashboardSelectedOrders,
  DashboardNewManifestDropArea,
  DashboardNewManifestCreated,
  DashboardMeetManifestSection,
  DashboardOptimizeManifest,
  DashboardOptimizeManifestDone,
  DashboardSendManifest,
  DashboardSendManifestDone,
  Completion,
}

export enum ESafetyInspectionFrequency {
  Always = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Mileage = 4,
}

export enum ESafetyDays {
  'Sunday' = 0,
  'Monday' = 1,
  'Tuesday' = 2,
  'Wednesday' = 3,
  'Thursday' = 4,
  'Friday' = 5,
  'Saturday' = 6,
}

export enum ESafetyInspectionStatus {
  Pending = 0,
  Pass = 1,
  Fail = 2,
  Repaired = 3,
  'Critical Fail' = 4,
}

export enum EStorageProperties {
  UsersSearchString = 'users_search_string',
  UsersPageSettings = 'users_page_settings',
  UsersSortSettings = 'users_sort_settings',
  OrdersFilters = 'orders_filters',
  OrdersSearchString = 'orders_search_string',
  OrdersPageSettings = 'orders_page_settings',
  OrdersSortSettings = 'orders_sort_settings',
  AccountsSearchString = 'accounts_search_string',
  AccountsSortSettings = 'accounts_sort_settings',
  AccountsPageSettings = 'accounts_page_settings',
  CustomersSearchString = 'customers_search_string',
  CustomersPageSettings = 'customers_page_settings',
  CustomersSortSettings = 'customers_sort_settings',
  DriversSearchString = 'drivers_search_string',
  DriversPageSettings = 'drivers_page_settings',
  DriversSortSettings = 'drivers_sort_settings',
  ManifestsFilters = 'manifests_filters_v3',
  ManifestsSortSettings = 'manifests_sort_settings_v3',
  ManifestsPageSettings = 'manifests_page_settings',
  VehiclesFilters = 'vehicles_filters_v3',
  VehiclesSortSettings = 'vehicles_sort_settings_v3',
  VehiclesPageSettings = 'vehicles_page_settings',
  CurrentSessionDate = 'current_session_date',
  TimeTablesSortSettings = 'time_tables_sort_settings',
  TimeTablesPageSettings = 'time_tables_page_settings',
  SafetyReportsFilters = 'SafetyReportsFilters',
  SafetyReportsPageSettings = 'safety_report_page_settings',
  SafetyReportsSortSettings = 'safety_report_page_settings',
  SafetyPlansPageSettings = 'safety_plans_page_settings',
  SafetyPlansSortSettings = 'safety_plans_sort_settings',
  DashboardMapBounds = 'dashboard_map_bounds',
  DashboardMapZooming = 'dashboard_map_zooming',
  ZonesMapBounds = 'zones_map_bounds',
  ZonesMapZooming = 'zones_map_zooming',
  ImportInfoShowing = 'import_info_showing',
  ImportInfoCollapsing = 'import_info_collapsing',
  LiveMapVersion = 'live_map_version',
  LiveMapFilter = 'live_map_filter',
}

export enum EOrderSettingTypes {
  Delivery = 1,
  Pickup = 2,
}

export enum ELicenseValidationStatus {
  None = 0,
  NotValidated = 1,
  Validated = 2,
  Declined = 3,
}

export enum ELicenseExpirationStatus {
  // None = 0, not used
  Ok = 1,
  AboutToExpire = 2,
  Expired = 3,
}

export enum EImportStatus {
  Process = 1,
  Finished = 2,
  Fail = 3,
  Cancelled = 4,
  CancelRequest = 5
}

export enum CurrencyType
{
    Aud = 0,
    Usd = 1,
    Eur = 2,
    Gbp = 3
}

export enum MeasurementSystemType
{
    Metric = 0,
    Imperial = 1,
}


export enum ELiveMapVersion {
  Old = 'old',
  New = 'new'
}

export enum EIntegrationType {
  Mix = 'Mix',
  Linxio = 'Linxio'
}

import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Moment } from 'moment';

import { EDatePresets } from '../consts/enums';
import { DateRange, timeStamp, timeString } from '../models/interfaces';
import { NBSP } from '../consts/constants';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  constructor(
    private datePipe: DatePipe,
  ) { }

  public UTCDate = (date: Date | string): string => {
    const _date = new Date(date);
    _date.setMinutes(_date.getMinutes() - _date.getTimezoneOffset());
    return _date.toISOString();
  }

  public UTCTime = (date: Date | string): string => {
    return this.datePipe.transform(date, 'HH:mm', '+0000');
  }

  public convert12To24Hours = (time: string): string => {
    const is12Format = time.split(' ').length > 1;
    if (!is12Format) {
      return time;
    }
    const parsedTime = moment(time, 'h:mm a');
    return parsedTime.format('HH:mm');
  }

  public getToday = (): Moment => {
    return moment().utc(true).startOf('day');
  }

  public getDateRange = (preset: EDatePresets): DateRange => {
    const startDate = moment().utc(true);
    const endDate = moment().utc(true);

    const dateRange: DateRange = {
      fromDate: null,
      toDate: null,
    };

    const presetActions: Record<EDatePresets, () => void> = {
      [EDatePresets.Today]: () => {
        dateRange.fromDate = startDate.startOf('day');
        dateRange.toDate = endDate.endOf('day');
      },
      [EDatePresets.Yesterday]: () => {
        dateRange.fromDate = startDate.subtract(1, 'day').startOf('day');
        dateRange.toDate = endDate.subtract(1, 'day').endOf('day');
      },
      [EDatePresets.Tomorrow]: () => {
        dateRange.fromDate = startDate.add(1, 'day').startOf('day');
        dateRange.toDate = endDate.add(1, 'day').endOf('day');
      },
      [EDatePresets.ThisWeek]: () => {
        dateRange.fromDate = startDate.startOf('week');
        dateRange.toDate = endDate.endOf('week');
      },
      [EDatePresets.LastWeek]: () => {
        dateRange.fromDate = startDate.subtract(1, 'week').startOf('week');
        dateRange.toDate = endDate.subtract(1, 'week').endOf('week');
      },
      [EDatePresets.LastSevenDays]: () => {
        dateRange.fromDate = startDate.subtract(6, 'day').startOf('day');
        dateRange.toDate = endDate.endOf('day');
      },
      [EDatePresets.LastThirtyDays]: () => {
        dateRange.fromDate = startDate.subtract(29, 'day').startOf('day');
        dateRange.toDate = endDate.endOf('day');
      },
      [EDatePresets.ThisMonth]: () => {
        dateRange.fromDate = startDate.startOf('month');
        dateRange.toDate = endDate.endOf('month');
      },
      [EDatePresets.PreviousMonth]: () => {
        dateRange.fromDate = startDate.subtract(1, 'month').startOf('month');
        dateRange.toDate = endDate.subtract(1, 'month').endOf('month');
      },
    };
    presetActions[preset]();

    return dateRange;
  }

  public isEqualDates = (a, b) => {
    const aDate = moment(a).startOf('day');
    const bDate = moment(b).startOf('day');
    return aDate.isSame(bDate);
  }

  public toDateObject = (dateString: string): Moment => {
    return moment(dateString, 'YYYY-MM-DD');
  }

  public fromDateObject = (dateObject: Moment, utcFormat = false): string => {
    return utcFormat ? dateObject.toISOString() : dateObject.format('YYYY-MM-DD');
  }

  public toDateTimeObject = (dateTimeString: timeStamp): Moment => {
    return moment(dateTimeString, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
  }

  public fromDateTimeObject = (dateObject: Moment): string => {
    return dateObject.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  }

  public dateStrToTimeStr = (dateTimeString: timeStamp): timeString => {
    return moment(dateTimeString).utc().toISOString().slice(11, 16);
  }

  public setTimeToDate = (time: timeString, date: Moment): Moment => {
    const newDate = date.clone();
    const timeArr: number[] = time.split(':').map(value => Number(value));
    newDate.set('hours', timeArr[0]);
    newDate.set('minutes', timeArr[1]);
    newDate.set('seconds', 0);
    newDate.set('milliseconds', 0);
    return newDate;
  }

  public addMinutesToTimeString = (minutes: number, time: timeString): timeString => {
    const dateObj = moment(time, 'HH:mm');
    dateObj.add(minutes, 'minutes');
    return dateObj.format('HH:mm');
  }

  public minutesToString = (minutes: number): timeString => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(mins).padStart(2, '0');

    return `${paddedHours}:${paddedMinutes}`;
  }

  public getTimeRangeInMinutes = (prevTimeStr: timeString, curTimeStr: timeString): number => {
    const timeArr = curTimeStr.split(':').map(value => Number(value));
    const startTimeArr = prevTimeStr.split(':').map(value => Number(value));
    const result = ((timeArr[0] * 60 + timeArr[1]) - (startTimeArr[0] * 60 + startTimeArr[1]));
    return result >= 0 ? result : 24 * 60 + result;
  }

  public getRangeTimeInMinutes = (startTime: timeString, endTime: timeStamp): number => {
    const startTimeArr = startTime.split(':');
    const startTimeInMinutes = Number(startTimeArr[0]) * 60 + Number(startTimeArr[1])
      + (Number(startTimeArr[2]) ? Number(startTimeArr[2]) / 60 : 0);

    const endTimeArr = endTime.split('T')[1].split(':');
    const endTimeInMinutes = Number(endTimeArr[0]) * 60 + Number(endTimeArr[1])
      + Number(endTimeArr[2].substring(0, 2)) / 60;

    return (endTimeInMinutes - startTimeInMinutes);
  }

  public getRangeHoursArray = (startHour: timeString, endHour: timeString): timeString[] => {
    const start: number = +startHour.split(':')[0];
    const end: number = +endHour.split(':')[0];
    const length = (end - start) + 1;

    return Array.from({ length }, (v, i) => {
      const hour = start + i;
      return `${hour >= 10 ? hour : '0' + hour}:00`;
    });
  }

  public getHoursAndMinutesFromSeconds = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    const result = [(hours ? `${hours}h` : ''), `${minutesStr}m`];
    return result.join(NBSP);
  }

  public roundFloorTime = (time: timeStamp): timeString => {
    return `${time.split('T')[1].split(':')[0]}:00`;
  }

  public isToday = (dateString: timeStamp): boolean => {
    return this.toDateTimeObject(dateString).utc(true).startOf('day').isSame(this.getToday());
  }

  public getTimeSlots = (hour: timeString, interval: number): timeString[] => {
    const [hours, minutes] = hour.split(':').map(Number);

    const startTime = new Date();
    startTime.setHours(hours, minutes, 0, 0);

    const endTime = new Date(startTime);
    endTime.setHours(hours + 1, 0, 0, 0);

    return Array.from(
      { length: Math.ceil((endTime.getTime() - startTime.getTime()) / (interval * 60 * 1000)) + 1 },
      (_, index) => {
        const currentTime = new Date(startTime.getTime() + index * interval * 60 * 1000);
        return currentTime.toTimeString().slice(0, 5);
      }
    );
  }
}

import {
  Component, AfterViewChecked, ChangeDetectorRef, OnInit, ElementRef,
  HostBinding, Inject, OnDestroy, Renderer2, ViewEncapsulation, ViewChild
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { Subscription } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config.service';
import { ImportInfoService } from '@fuse/components/import-info/import-info.service';
import { ApiService } from 'app/services/api.service';
import { SharedService } from 'app/services/shared.service';
import { WorkspacePlansService } from '../modules/workspace-plans/services/workspace-plans.service';
import { EModuleIDs } from '../consts/enums';

@Component({
  selector: 'fuse-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FuseMainComponent implements OnDestroy, OnInit, AfterViewChecked {
  onConfigChanged: Subscription;
  fuseSettings: any;
  @ViewChild('quickNavBar', { static: true }) quickNavBar: any;
  @HostBinding('attr.fuse-layout-mode') layoutMode;

  readonly importStatus$ = this.importInfoService.importFinalStatus$;

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private fuseConfig: FuseConfigService,
    private platform: Platform,
    public api: ApiService,
    public sharedService: SharedService,
    private cdRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: any,
    private importInfoService: ImportInfoService,
    private workspacePlansService: WorkspacePlansService,
  ) {
    this.onConfigChanged = this.fuseConfig.onConfigChanged.subscribe(
      (newSettings) => {
        this.fuseSettings = newSettings;
        this.layoutMode = this.fuseSettings.layout.mode;
      }
    );

    if (this.platform.ANDROID || this.platform.IOS) {
      this.document.body.className += ' is-mobile';
    }
  }

  ngOnInit() {
    this.importInfoService.fetchImportHistoryByTimer({ immediately: true });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.onConfigChanged.unsubscribe();
  }

  addClass(className: string): void {
    this._renderer.addClass(this._elementRef.nativeElement, className);
  }

  removeClass(className: string): void {
    this._renderer.removeClass(this._elementRef.nativeElement, className);
  }

  public hasShowImports = (): boolean => {
    return !this.importInfoService.isVisible && this.importInfoService.getImportsList().length > 0;
  }

  public hasShowWorkspacePlans = (): boolean => {
    return this.workspacePlansService.isAvailableModule();
  }

  public plansLink = (): string => {
    return this.sharedService.linkToPage(EModuleIDs.WorkspacePlans);
  }

  public onShowImports = (): void => {
    this.importInfoService.show();
    this.importInfoService.open();
  }

}

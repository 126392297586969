import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorkspacePlansService {

  public isAvailableModule = (): boolean => {
    return environment.workspacePlans;
  }
}

<mat-toolbar data-testid="footer-component">

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

        <a target="_blank" mat-button class="mat-pink-bg" fxFlex="0 0 auto" fxLayout="row"
           fxLayoutAlign="start center" data-testid="footer-subscription-button">
            <mat-icon class="s-16 mr-sm-4" data-testid="footer-cart-icon">shopping_cart</mat-icon>
            <span data-testid="footer-subscription-text">Purchase subscribtion</span>
        </a>

        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs data-testid="footer-links">
            <a mat-button href="#" target="_blank" data-testid="footer-docs-link">Documentation</a>
            <span data-testid="footer-separator">&bull;</span>
            <a mat-button href="#" target="_blank" data-testid="footer-changelog-link">Changelog</a>
        </div>

    </div>

</mat-toolbar>

<mat-progress-bar
  class="loading-bar"
  *ngIf="api.loading"
  color="accent"
  mode="indeterminate"
  data-testid="main-loading-bar"
></mat-progress-bar>

<mat-sidenav-container data-testid="main-sidenav-container">

  <div *ngIf="sharedService.isReady" id="fuse-main-content" data-testid="main-content">

    <!-- TOOLBAR: Above -->
    <ng-container *ngIf="fuseSettings.layout.toolbar === 'above'">
      <fuse-toolbar
        class="above"
        [ngClass]="fuseSettings.colorClasses.toolbar"
        data-testid="main-toolbar-above"
      ></fuse-toolbar>
    </ng-container>
    <!-- / TOOLBAR: Above -->

    <!-- NAVBAR: Top -->
    <fuse-navbar
      layout="horizontal"
      class="top-navbar"
      fxHide
      fxShow.gt-md
      [ngClass]="fuseSettings.colorClasses.navbar"
      *ngIf="fuseSettings.layout.navigation === 'top'"
      data-testid="main-navbar-top"
    ></fuse-navbar>
    <!-- / NAVBAR: Top -->

    <div id="wrapper" data-testid="main-wrapper">

      <!-- NAVBAR: Left -->
      <fuse-sidebar
        [name]="'navbar'"
        [folded]="fuseSettings.layout.navigationFolded"
        [lockedOpen]="'gt-md'"
        class="left-navbar custom-branding"
        [ngClass]="fuseSettings.colorClasses.navbar"
        data-testid="main-sidebar-left"
      >
        <fuse-navbar layout="vertical" data-testid="main-navbar-vertical"></fuse-navbar>
      </fuse-sidebar>
      <!-- / NAVBAR: Left -->

      <div class="content-wrapper" data-testid="main-content-wrapper">

        <!-- TOOLBAR: Below -->
        <ng-container *ngIf="fuseSettings.layout.toolbar === 'below'">
            <fuse-toolbar
              class="below"
              [ngClass]="fuseSettings.colorClasses.toolbar"
              data-testid="main-toolbar-below"
            >
              <button
                *ngIf="hasShowWorkspacePlans()"
                mat-flat-button
                matTooltip="Workspace plans"
                class="plans-button"
                [routerLink]="plansLink()"
                data-testid="main-upgrade-button"
              >
                <div class="flex align-center">
                  <span data-testid="main-upgrade-text">Upgrade now</span>
                  <mat-icon
                    class="ml-4"
                    [svgIcon]="'arrow_up_circle'"
                    [class]="'orange'"
                    data-testid="main-upgrade-icon"
                  ></mat-icon>
                </div>
              </button>
              <button
                *ngIf="hasShowImports()"
                mat-stroked-button
                matTooltip="Show import details"
                (click)="onShowImports()"
                data-testid="main-imports-button"
              >
                <div class="flex align-center">
                  <span data-testid="main-imports-text">Imports</span>
                  <fuse-import-status-icon
                    [status]="importStatus$ | async"
                    class="ml-4"
                    data-testid="main-import-status-icon"
                  ></fuse-import-status-icon>
                </div>
              </button>
            </fuse-toolbar>
        </ng-container>
        <!-- / TOOLBAR: Below -->

        
        <fuse-content *ngIf="sharedService.isReady" data-testid="main-content-component"></fuse-content>

        <!-- FOOTER: Below -->
        <!--
        <ng-container *ngIf="fuseSettings.layout.footer === 'below'">
            <fuse-footer class="below" [ngClass]="fuseSettings.colorClasses.footer" data-testid="main-footer-below"></fuse-footer>
        </ng-container>
        -->
        <!-- / FOOTER: Below -->
        <fuse-import-info data-testid="main-import-info"></fuse-import-info>
      </div>

      <!-- NAVBAR: Right -->
      <fuse-sidebar
        [name]="'navbar'"
        [align]="'right'"
        [folded]="fuseSettings.layout.navigationFolded"
        [lockedOpen]="'gt-md'"
        class="right-navbar custom-branding"
        [ngClass]="fuseSettings.colorClasses.navbar"
        *ngIf="fuseSettings.layout.navigation === 'right'"
        data-testid="main-sidebar-right"
      >
        <fuse-navbar layout="vertical" data-testid="main-navbar-vertical-right"></fuse-navbar>
      </fuse-sidebar>
      <!-- / NAVBAR: Right -->

    </div>

    <!-- FOOTER: Above -->
    <ng-container *ngIf="fuseSettings.layout.footer === 'above'">
      <fuse-footer
        class="above"
        [ngClass]="fuseSettings.colorClasses.footer"
        data-testid="main-footer-above"
      ></fuse-footer>
    </ng-container>
    <!-- FOOTER: Above -->

    </div>
</mat-sidenav-container>

<fuse-theme-options data-testid="main-theme-options"></fuse-theme-options>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import {
  IAutoallocateResult,
  IEventMessageData,
  IServerMessageStreamInput,
} from 'app/models/interfaces';
import { ApiService } from 'app/services/api.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EModuleIDs } from '../../../../consts/enums';
import { SharedService } from 'app/services/shared.service';
import { NoticeService } from 'app/services/notice.service';

@Component({
  templateUrl: './server-message-stream-dialog.component.html',
  styleUrls: ['./server-message-stream-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServerMessageStreamDialogComponent implements OnInit, OnDestroy {
  @ViewChild('terminal', { static: true }) private terminalWindow: ElementRef<HTMLDivElement>;
  @ViewChild('solutionFile') private solutionFileLink: ElementRef<HTMLAnchorElement>;
  public progressBarValue = 0;
  public messages: Array<{
    text: SafeHtml,
    class?: string,
  }> = [];
  private eventSource: EventSource;

  public allocationResult: IAutoallocateResult;
  public fileLink = '';
  cancelled = false;
  /** stream reading is finish */
  readingIsFinished = false;

  /** async backgroup process is finished */
  asyncProcessIsFinished = false;

  private readonly markForCheck = new Subject();
  private readonly subscriptions = [
    this.markForCheck.pipe(debounceTime(500)).subscribe(() => {
      this.cdr.detectChanges();
      this.scrollToTerminalBottom();
    })
  ];

  get isAsyncImport() {
    return this.data.asyncImportId;
  }

  public initializing = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IServerMessageStreamInput,
    private dialogRef: MatDialogRef<ServerMessageStreamDialogComponent, IAutoallocateResult>,
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private api: ApiService,
    private sharedService: SharedService,
    private notice: NoticeService,
  ) {
    // @ts-ignore
    window.showOrder = (id: number) => window.open(this.sharedService.linkToPage(EModuleIDs.Orders, id), '_blank');
    // @ts-ignore
    window.showClient = (id: number) => window.open(this.sharedService.linkToPage(EModuleIDs.Customers, id), '_blank');
    // @ts-ignore
    window.showDriver = (id: number) => window.open(this.sharedService.linkToPage(EModuleIDs.Drivers, id), '_blank');
  }

  ngOnInit(): void {
    const eventSourceLink = this.data.eventSourceLink ?? this.api.getImportStreamLink(this.data.asyncImportId);

    if (!eventSourceLink) {
      return;
    }

    this.asyncProcessIsFinished = this.data.asyncFinished;

    this.eventSource = new EventSource(eventSourceLink);
    this.eventSource.onmessage = (e: MessageEvent) => {
      const messageData: IEventMessageData = JSON.parse(e.data);
      this.addMessage(messageData);
      this.initializing = false;
      this.markForCheck.next();
    };

    this.eventSource.onerror = () => {
      this.eventSource.close();
      this.progressBarValue = 100;
      this.readingIsFinished = true;
      this.markForCheck.next();
    };

  }

  private addMessage(messageData: IEventMessageData) {
    if (messageData.current_idx && messageData.tot_qty) {
      this.progressBarValue = messageData.current_idx / messageData.tot_qty * 100;
    }

    if (this.data.allocateOrderToManifest === true && messageData.data) {
      this.allocationResult = JSON.parse(messageData.data);
    }

    if (messageData.file) {
      const xlsContent = (
        'data:application/octet-stream;charset=utf-16le;base64,'
        + messageData.file
      );
      this.fileLink = this.domSanitizer.sanitize(
        SecurityContext.URL,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          encodeURI(xlsContent)
        ),
      );
      const link = this.solutionFileLink.nativeElement;
      link.setAttribute('href', this.fileLink);
      link.setAttribute('download', 'solution.xlsx');
      link.text = 'solution.xlsx';
      this.solutionFileLink.nativeElement.click();
    }

    this.messages.push({
      text: this.domSanitizer.bypassSecurityTrustHtml(
        messageData.message
      ),
      class: messageData.is_error ? 'error' : '',
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());

    if (this.eventSource) {
      this.eventSource.close();
    }
  }

  private scrollToTerminalBottom = (): void => {
    this.terminalWindow.nativeElement.scrollIntoView(false);
  }

  public allocate = (): void => {
    this.dialogRef.close(this.allocationResult);
  }

  public cancelImport = () => {
    this.cancelled = true;
    this.api.cancelImport(this.data.asyncImportId).subscribe(() => {
      this.notice.showSnackBar('Cancellation process requested. You can close the pop-up window.');
    });
  }
}

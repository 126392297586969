<mat-toolbar class="p-0 mat-elevation-z1" data-testid="toolbar-component">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center" data-testid="toolbar-container">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center" data-testid="toolbar-left-section">
      <button
        *ngIf="!noNav"
        mat-button
        class="toggle-button-navbar mat-icon-button"
        (click)="toggleSidebarOpened('navbar')"
        fxHide.gt-md
        data-testid="toolbar-toggle-sidebar-button"
      ><mat-icon data-testid="toolbar-menu-icon">menu</mat-icon></button>

      <div class="toolbar-separator" fxHide.gt-md data-testid="toolbar-separator"></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNav" data-testid="toolbar-horizontal-nav">
        <div class="logo ml-16" data-testid="toolbar-logo">
          <img class="logo-icon" src="assets/branding/default/logo.png" onerror="this.onerror=null; this.src='assets/branding/logo.svg'" data-testid="toolbar-logo-img">
        </div>
      </div>
    </div>

    <ng-container *ngIf="isExtendedNavigationElement && isNewLiveMap">
      <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center" class="center-buttons" data-testid="toolbar-center-buttons">
        <a class="nav-link live-map" [routerLink]="'/app/live-map/planning'" routerLinkActive="active" data-testid="toolbar-planning-mode-link">
          <span data-testid="toolbar-planning-mode-text">Planning mode</span>
        </a>
<!--        <a class="nav-link live-map" [routerLink]="'/app/live-map/live'" routerLinkActive="active">-->
<!--          <span style="margin-left: 10px">Live mode</span>-->
<!--        </a>-->
        <a class="nav-link live-map disabled" (click)="onComingSoon()" data-testid="toolbar-live-mode-link">
          <span style="margin-left: 10px" data-testid="toolbar-live-mode-text">Live mode</span>
        </a>
      </div>
    </ng-container>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center" style="gap: 8px" data-testid="toolbar-right-section">
      <ng-content select="button"></ng-content>
<!--      <button-->
<!--        class="header-action-button"-->
<!--        mat-icon-button-->
<!--        matTooltip="Send message to driver"-->
<!--        (click)="sendMessageToVehicleClick()"-->
<!--      ><mat-icon fontSet="material-icons-outlined">perm_phone_msg</mat-icon></button>-->

      <div class="toolbar-separator" data-testid="toolbar-right-separator"></div>

      <button
        mat-button
        class="user-button"
        [matMenuTriggerFor]="userMenu"
        data-testid="toolbar-user-button"
      >
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="avatar" src="assets/images/avatars/profile.jpg" data-testid="toolbar-avatar-img">
          <span class="username mr-12" fxHide fxShow.gt-sm data-testid="toolbar-username">{{loggedInUser}}</span>
          <mat-icon class="s-16" fxHide.xs data-testid="toolbar-arrow-icon">keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false" data-testid="toolbar-user-menu">
        <button
          mat-menu-item
          class="toolbar-menu-button"
          [routerLink]="accountLink()"
          data-testid="toolbar-account-button"
        >
          <span data-testid="toolbar-account-text">{{'Account: ' + sharedService.accountName}}</span>
        </button>

        <button
          *ngIf="workspacePlansService.isAvailableModule()"
          mat-menu-item
          class="toolbar-menu-button"
          [routerLink]="plansLink()"
          data-testid="toolbar-plans-button"
        ><span data-testid="toolbar-plans-text">Plans</span>
        </button>

        <button
          mat-menu-item
          class="toolbar-menu-button"
          (click)="logOut()"
          data-testid="toolbar-logout-button"
        ><span data-testid="toolbar-logout-text">Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>

import { Injectable } from '@angular/core';

import * as moment from 'moment';

import { EStorageProperties } from '../consts/enums';
import { DateTimeService } from './date-time.service';

interface IDataRecord<T> {
  data: T;
  createdAt: string;
  onlyForCurrentSession: boolean;
  onlyForCurrentDay: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private dateTime: DateTimeService,
  ) {}

  public write = <T>(
    property: EStorageProperties,
    value: T,
    onlyForCurrentSession = false,
    onlyForCurrentDay = false,
  ): void => {
    const record: IDataRecord<T> = {
      data: value,
      createdAt: moment().toISOString(),
      onlyForCurrentSession,
      onlyForCurrentDay
    };
    localStorage.setItem(property, JSON.stringify(record));
  }

  public read = <T>(property: EStorageProperties): T | null => {
    const dataRecord: IDataRecord<T> = JSON.parse(localStorage.getItem(property));
    if (!dataRecord) {
      return null;
    }
    if (dataRecord.onlyForCurrentSession) {
      const currentSessionDate = this.read<string>(EStorageProperties.CurrentSessionDate);
      if (Boolean(currentSessionDate) && dataRecord.createdAt < currentSessionDate) {
        this.clean(property);
        return null;
      }
    }
    if (dataRecord.onlyForCurrentDay && !this.dateTime.isToday(dataRecord.createdAt)) {
      this.clean(property);
      return null;
    }
    return dataRecord.data;
  }

  public clean = (property: EStorageProperties): void => {
    localStorage.setItem(property, null);
  }
}

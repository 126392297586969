import {
  ChangeDetectorRef,
  Injectable,
  OnDestroy,
  Optional,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { CurrencyType, MeasurementSystemType } from 'app/consts/enums';
import { AccountConfigService } from 'app/services/account-config.service';
import { Subject, Subscription } from 'rxjs';

export type Unit = 'distance' | 'weight' | 'length' | 'currency' | 'currencyCode' | 'volume';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'regional',
  pure: false,
})
export class RegionalPipe implements PipeTransform, OnDestroy {
  private table: Partial<Record<Unit, string>> = {};
  updated$ = new Subject();
  private configSubscription: Subscription;

  constructor(
    private accountConfigService: AccountConfigService,
    @Optional() private cdr?: ChangeDetectorRef,
  ) { }

  ngOnDestroy(): void {
    this.logout();
  }

  transform(value: Unit) {
    this.lazyInit();
    return this.table[value] ?? '';
  }

  logout() {
    this.configSubscription?.unsubscribe();
    this.configSubscription = null;
  }

  private lazyInit() {
    //lazy loading to avoid requesting before authorization
    if (this.configSubscription) {
      return;
    }

    this.configSubscription = this.accountConfigService.config$.subscribe(c => {
      this.table = {
        ...measurementSystemMap[c.measurementSystem ?? MeasurementSystemType.Metric],
        ...currencyMap[c.currency ?? CurrencyType.Aud],
      };
      this.updated$.next();
      this.cdr?.markForCheck();
    });
  }
}

const measurementSystemMap: Record<MeasurementSystemType, Partial<Record<Unit, string>>> = {
  [MeasurementSystemType.Metric]: { distance: 'km', weight: 'kg', length: 'm', volume: 'm3' },
  [MeasurementSystemType.Imperial]: { distance: 'mi', weight: 'lb', length: 'ft', volume: 'ft3' },
};

const currencyMap: Record<CurrencyType, Partial<Record<Unit, string>>> = {
  [CurrencyType.Aud]: { currency: '$', currencyCode: 'AUD' },
  [CurrencyType.Eur]: { currency: '€', currencyCode: 'EUR' },
  [CurrencyType.Gbp]: { currency: '£', currencyCode: 'GBP' },
  [CurrencyType.Usd]: { currency: '$', currencyCode: 'USD' },
};

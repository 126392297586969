import { Injectable, Injector } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpErrorResponse, HttpParams,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap, reduce } from 'rxjs/operators';

import { environment } from 'environments/environment';

import {
  IAccountParent,
  IAccountSummary,
  IAccountDetail,
  IAccount,
  IAccountConfig,
  IAccountConfigFull,
  IAccountFull,
  IAccountModule,
  IAttribute,
  ICustomerFull,
  ICustomerSearchAnswer,
  IDriver,
  IDriverFull,
  IManifestForAddRequest,
  IManifestsAddResponse,
  ILoadType,
  ILoadOption,
  IOrder,
  IOrderFull,
  IOrderResponse,
  IOrdersGetParameters,
  IOrdersResponse,
  IRouteOptimizationRequest,
  ITimetable,
  ITimetableFull,
  IUnitLogRecord,
  IUserModule,
  IUserSummary,
  IUserDetail,
  IUser,
  IUserType,
  //  IUserFull,
  IVehicleGroup,
  IVehicleGroupNew,
  IVehicle,
  IVehicleCombined,
  IVehicleDeleteOrderResponse,
  IVehicleFull,
  numberBoolean,
  IManifest,
  IManifestToVehicleResponse,
  IZone,
  IManifestDuplicateRequest,
  IManifestFull,
  IManifestLinkingRequest,
  IManifestLinkingResponse,
  IModule,
  IAppData,
  IManifestSplitRequest,
  IManifestDeleteRequest,
  IManifestsToVehicleResponse,
  IManifestNotifyRequest,
  IOrdersToManifestRequest,
  IAccountNameValidation,
  IPhoneNumberValidation,
  IApiResponse,
  IVerificationResponse,
  IDriverNew,
  ISafetyImage,
  ISafetyItem,
  ISafetyInspectionBase,
  ISafetyInspection,
  ISafetyPlan,
  ISafetyPlanBase,
  ISafetyRepairer,
  ISafetyVehicleListItem,
  ITimezone,
  IFile,
  ISafetyPlanToSave,
  IListData,
  ICapacityType,
  IMapServer,
  IConsignmentNote,
  MapTilerFeatureCollection, ISafetyItemsGroup, ISafetyItemsGroupRequest, IImportHistory, IImportHistoryDetails,
  ILiveMapManifestsParams,
  ILiveMapOrdersParams,
  LoadTypeDto,
  IVehicleFullDto
} from 'app/models/interfaces';
import { EMapTypes, EOrderImportOptions, ERoles, ETimers } from 'app/consts/enums';
import { IImportOrdersDialogOutput } from 'app/main/content/orders/dialogs/import-orders-dialog/import-orders-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IOnboardingStatus } from 'app/main/content/onboarding/steps/onboarding-stepper.component';
import { IVerificationDetails } from 'app/main/content/onboarding/steps/onboarding-stepper.component';
import { BulkUpdateOrderRequestData, IManifestCoords } from '../main/content/manifests/types/types';
import {
  Feedback,
  FeedbackRequestParams,
  FeedbackStats,
  FeedbackTrends,
  StatsRequestParams,
  TrendsRequestParams
} from '../modules/analytics/shared/types/types';
import { DateTimeService } from './date-time.service';
import { NEW_ID } from 'app/consts/constants';
import { IBulkDuplicateOrderRequest } from '../main/content/orders/types';
import { join } from 'path';
import { IVehicleShortDto } from 'app/models/vehicle-short-dto.type';
import {
  ILiveMapManifest,
  ILiveMapManifestCreate,
  ILiveMapManifestUpdate, ILiveMapVehicle, ILiveMapVehicleGetDTO,
  IUnassignedOrder,
} from '../modules/live-map/types';
import { createHttpClient } from '../../@fuse/services/http-client.factory';

interface Response<T> {
  data: T;
  message: string;
  status: number;
  errors: Array<unknown>;
}

@Injectable({ providedIn: 'root' })
export class ApiService {
  
  private API_REPORTS = environment.reportViewerURL;

  private accountID: number;
  private APIKEY: string;
  private MAPTILER_APIKEY = 'SBEE89GicRjD5jkKPbkS';

  public requestQuantity = 0;
  public loading: boolean;
  private httpWithoutLoader: HttpClient;

  constructor(
    private http: HttpClient,
    private dateTime: DateTimeService,
    private injector: Injector
  ) {
    this.httpWithoutLoader = createHttpClient(this.injector, [HttpInterceptorApi]);
  }

  //#region Common

  public setAccountInfo = (accountID: number, APIKEY: string): void => {
    this.accountID = accountID;
    this.APIKEY = APIKEY;
  }

  public getModules = (): Observable<Array<IModule>> => {
    return this.http.get<Array<IModule>>('api/v2/module/GetModules');
  }

  public getAppData = (): Observable<IAppData> => {
    return this.http.get<IAppData>('API/GetCounts');
  }

  public getMapCountryCode = (): Observable<{ data?: { countryCode?: string } }> => {
    return this.http.get<{ data?: { countryCode?: string } }>('API/V2/Account/GetMapCountryCode');
  }

  public getMapTilesURL = (mapType: EMapTypes): string => {
    switch (mapType) {
      case EMapTypes.Hybrid:
        return `https://api.maptiler.com/maps/3260f8a6-a9dd-4282-a392-0134022672bb/{z}/{x}/{y}.jpg?key=${this.MAPTILER_APIKEY}`;
      default:
        return `https://api.maptiler.com/maps/eb7ec7ce-a72b-423f-9d7b-b3fe2636f4d4/{z}/{x}/{y}.png?key=${this.MAPTILER_APIKEY}`;
    }
  }

  public getPlaceByName = (placeName: string): Observable<MapTilerFeatureCollection> => {
    const str = placeName.replace(/\//g, '%2F');
    return this.http.get<MapTilerFeatureCollection>(`https://api.maptiler.com/geocoding/${str}.json?key=${this.MAPTILER_APIKEY}`);
  }

  public getPlaceByCoordinates = ({ lat, lng }: { lat: number, lng: number }): Observable<MapTilerFeatureCollection> => {
    return this.http.get<MapTilerFeatureCollection>(`https://api.maptiler.com/geocoding/${lng},${lat}.json?key=${this.MAPTILER_APIKEY}`);
  }
  //#endregion Common

  //#region Auth

  public login = (
    accountName: string,
    userName: string,
    password: string,
    apiKeyValue: string,
    captchaToken: string,
  ) => {
    const loginBody = {
      AccountName: accountName,
      UserName: userName,
      password: password,
      APIKEY: apiKeyValue,
      recaptcha: captchaToken,
    };
    return this.http.post<{ role: ERoles, onBoardingCompleted: boolean, inActive: boolean, trialPeriod: boolean, inactiveDays: number, locale: string }>(
      'API/auth/login', loginBody, { observe: 'response' }
    );
  }

  public logout = (): Observable<HttpResponse<null>> => {
    return this.http.post<null>(
      'API/auth/SignOut', {}, { observe: 'response' }
    );
  }

  public validatePhoneNumber = (
    phoneNumber: string,
    hasPlus: boolean,
  ): Observable<HttpResponse<IPhoneNumberValidation>> => {
    return this.http.get<IPhoneNumberValidation>(
      `API/v2/registration/validatePhone?PhoneNumber=${phoneNumber}&HasPlus=${hasPlus}`, { observe: 'response' }
    );
  }

  public validateAccountName = (
    accountName: string,
  ): Observable<HttpResponse<IAccountNameValidation>> => {
    return this.http.get<IAccountNameValidation>(
      `API/v2/registration/validateAccount/${accountName}`, { observe: 'response' }
    );
  }

  public signup = (
    accountName: string,
    email: string,
    phone: string,
    contactName: string,
    contactPassword: string,
    source: string
  ): Observable<HttpResponse<IApiResponse<null>>> => {
    const requestBody = {
      AccountName: accountName,
      Email: email,
      Phone: phone,
      ContactName: contactName,
      ContactPassword: contactPassword,
      Domain: window.location.host,
      Source: source
    };

    return this.http.post<IApiResponse<null>>(
      'API/v2/registration/register', requestBody, { observe: 'response' }
    );
  }

  public verifyAccount = (
    accountName: string,
    phone: string,
    email: string,
    contactName: string,
    userPassword: string,
    token: string,
    agreeTerms: boolean,
  ): Observable<HttpResponse<IVerificationResponse>> => {
    const requestBody = {
      AccountName: accountName,
      PhoneNumber: phone,
      EmailAddress: email,
      ContactName: contactName,
      UserPassword: userPassword,
      ConfirmationToken: token,
      AgreeTerms: agreeTerms,
    };

    return this.http.post<IVerificationResponse>(
      'API/v2/registration/confirm', requestBody, { observe: 'response' }
    );
  }

  public verifyToken = (
    token: string
  ): Observable<IApiResponse<IVerificationDetails>> => {
    return this.http.get<IApiResponse<IVerificationDetails>>(
      'API/v2/registration/verify/' + token,
    );
  }


  public sendPasswordRecoveryLinkToEmail = (
    accountName: string,
    email: string,
  ): Observable<HttpResponse<IApiResponse<null>>> => {
    const requestBody = {
      AccountName: accountName,
      EmailAddress: email,
      Domain: window.location.host,
    };

    return this.http.post<IApiResponse<null>>(
      'API/v2/user/PasswordReset', requestBody, { observe: 'response' }
    );
  }

  public setNewPassword = (
    userPassword: string,
    token: string,
  ): Observable<HttpResponse<IApiResponse<null>>> => {
    const requestBody = {
      Password: userPassword,
      Token: token,
    };

    return this.http.post<IApiResponse<null>>(
      'API/v2/user/NewPassword', requestBody, { observe: 'response' }
    );
  }

  //#endregion Auth

  //#region Onboarding

  public getOnboardingStatus = (): Observable<IApiResponse<IOnboardingStatus>> => {
    return this.http.get<IApiResponse<IOnboardingStatus>>(
      'API/v2/onboarding/status/' + this.accountID,
    );
  }

  public updateOnboardingStatus = (
    currentState: IOnboardingStatus,
  ): Observable<IApiResponse<IOnboardingStatus>> => {
    return this.http.post<IApiResponse<IOnboardingStatus>>(
      'API/v2/onboarding/status/' + this.accountID, currentState,
    );
  }

  //#endregion Onboarding

  //#region Settings

  public getTimezones = (): Observable<IApiResponse<Array<ITimezone>>> => {
    return this.http.get<IApiResponse<Array<ITimezone>>>(
      'API/v2/Account/getTimezones'
    );
  }

  public getConfigFull = (): Observable<HttpResponse<IAccountConfigFull>> => {
    return this.http.get<IAccountConfigFull>(
      'API/Account/getConfig', { observe: 'response' }
    );
  }

  public uploadLogo = (APIKey: string, file: File): Observable<HttpResponse<null>> => {
    const formData: FormData = new FormData();
    formData.append('apikey', APIKey);
    formData.append('file', file);
    return this.http.post<null>(
      'API/Account/uploadLogo', formData, { observe: 'response' },
    );
  }

  public saveAccountConfig = (
    data: IAccountConfig
  ): Observable<HttpResponse<null>> => {
    return this.http.post<null>(
      'API/Account/saveConfig', data, { observe: 'response' },
    );
  }

  public saveCompanyInfo = (
    data: IAccountConfigFull
  ): Observable<HttpResponse<null>> => {
    return this.http.post<null>(
      'API/v2/Account/saveCompanyInfo', data, { observe: 'response' },
    );
  }

  public addLoadType = (
    unit: ILoadType
  ): Observable<HttpResponse<Array<ILoadType>>> => {
    return this.http.post<Array<ILoadType>>(
      'API/MeasureUnits/addUnit', unit, { observe: 'response' },
    );
  }

  public saveLoadType = (
    unit: ILoadType
  ): Observable<HttpResponse<Array<ILoadType>>> => {
    return this.http.post<Array<ILoadType>>(
      'API/MeasureUnits/saveUnit', unit, { observe: 'response' },
    );
  }

  public deleteLoadType = (
    id: number
  ): Observable<HttpResponse<Array<ILoadType>>> => {
    return this.http.get<Array<ILoadType>>(
      'API/MeasureUnits/deleteUnit/' + id, { observe: 'response' },
    );
  }

  public addLoadOption = (
    unitStatus: ILoadOption
  ): Observable<HttpResponse<Array<ILoadOption>>> => {
    return this.http.post<Array<ILoadOption>>(
      'API/MeasureUnits/addUnitStatus', unitStatus, { observe: 'response' }
    );
  }

  public saveLoadOption = (
    unitStatus: ILoadOption
  ): Observable<HttpResponse<Array<ILoadOption>>> => {
    return this.http.post<Array<ILoadOption>>(
      'API/MeasureUnits/saveUnitStatus', unitStatus, { observe: 'response' }
    );
  }

  public deleteLoadOption = (
    id: number
  ): Observable<HttpResponse<Array<ILoadOption>>> => {
    return this.http.get<Array<ILoadOption>>(
      'API/MeasureUnits/deleteUnitStatus/' + id, { observe: 'response' }
    );
  }

  public deleteAttribute = (
    id: number
  ): Observable<HttpResponse<Array<IAttribute>>> => {
    return this.http.get<Array<IAttribute>>(
      'API/Account/removeAttribute/' + id, { observe: 'response' }
    );
  }

  public linkMYOBAccount = (): Observable<HttpResponse<string>> => {
    return this.http.get<string>(
      'API/myob/getActivationLink', { observe: 'response' }
    );
  }

  public generateSettingsLogoURL = (): string => {
    return this.generateAccountLogoURL(this.accountID);
  }

  public generateAnyLogoURL = (APIKey: string): string => {
    return this.generateLogoURL(APIKey);
  }

  public generateImageURL = (imageSrc: string): string => {
    return `img/${imageSrc}`;
  }

  public getPotentialIntegrations = (): Observable<HttpResponse<Array<any>>> => {
  return this.http.get<Array<any>>(
    'API/v2/PotentialIntegrations/GetPotentialIntegrations', 
    { observe: 'response' }
  );
};

  //#endregion Settings

  //#region Timetables

  public getAllTimetables = (): Observable<HttpResponse<Array<ITimetable>>> => {
    return this.http.get<Array<ITimetable>>(
      'API/DeliveryTimeTables/getAll', { observe: 'response' }
    );
  }

  public getTimetable = (id: number): Observable<HttpResponse<ITimetableFull>> => {
    return this.http.get<ITimetableFull>(
      'API/DeliveryTimeTables/Get/' + id, { observe: 'response' }
    );
  }

  public saveTimetable = (
    timetable: ITimetableFull
  ): Observable<HttpResponse<ITimetableFull>> => {
    return this.http.post<ITimetableFull>(
      'API/DeliveryTimeTables/Save', timetable, { observe: 'response' }
    );
  }

  public deleteTimetable = (id: number): Observable<HttpResponse<string>> => {
    return this.http.get<string>(
      'API/DeliveryTimeTables/Delete/' + id, { observe: 'response' }
    );
  }

  //#endregion Timetables

  //#region Drivers

  public getAllDrivers = (): Observable<HttpResponse<Array<IDriver>>> => {
    return this.http.get<Array<IDriver>>(
      'API/Drivers/allDrivers', { observe: 'response' }
    );
  }

  public getDriver = (id: number): Observable<HttpResponse<IDriverFull>> => {
    return this.http.get<IDriverFull>(
      'API/Drivers/getDriver/' + id, { observe: 'response' }
    );
  }

  public getExistingDrivers = (currentUserId: number): Observable<HttpResponse<Array<IUser>>> => {
    return this.http.get<Array<IUser>>(
      'API/Account/getExistingDrivers/' + currentUserId, { observe: 'response' }
    );
  }

  public saveDriver = (
    driver: IDriverFull
  ): Observable<HttpResponse<IApiResponse<IDriverFull>>> => {
    return this.http.post<IApiResponse<IDriverFull>>(
      'API/v2/Driver/SaveDriver', driver, { observe: 'response' }
    );
  }

  public createDriver = (
    driver: IDriverNew,
    photo: File,
  ): Observable<HttpResponse<IApiResponse<IDriverFull>>> => {
    const formData: FormData = new FormData();
    formData.append('DriverDetails', JSON.stringify(driver));
    formData.append('DriverPhoto', photo);

    return this.http.post<IApiResponse<IDriverFull>>(
      'API/v2/Driver/CreateDriver', formData, { observe: 'response' }
    );
  }

  public deleteDriver = (
    id: number
  ): Observable<HttpResponse<Array<IDriver>>> => {
    return this.http.get<Array<IDriver>>(
      'API/Drivers/deleteDriver/' + id, { observe: 'response' }
    );
  }

  public setLicenseMandatory = (driverId: number): Observable<HttpResponse<IApiResponse<null>>> => {
    return this.http.patch<IApiResponse<null>>(
      `api/v3/drivers/${driverId}/set-license-mandatory`, null, { observe: 'response' }
    );
  }

  public setLicenseNotMandatory = (driverId: number): Observable<HttpResponse<IApiResponse<null>>> => {
    return this.http.patch<IApiResponse<null>>(
      `api/v3/drivers/${driverId}/set-license-not-mandatory`, null, { observe: 'response' }
    );
  }

  public setAllLicenseNotMandatory = (): Observable<HttpResponse<IApiResponse<null>>> => {
    return this.http.post<IApiResponse<null>>(
      `api/v3/drivers/set-license-mandatory-for-everyone`, null, { observe: 'response' }
    );
  }

  public uploadFile = (formData: FormData): Observable<HttpResponse<IApiResponse<{ Files: IFile[] }>>> => {
    formData.set('APIKey', this.APIKEY);
    return this.http.post<IApiResponse<{ Files: IFile[] }>>(
      `/api/v2/file/upload/`, formData, { observe: 'response' }
    );
  }

  public getFiles = (entityId: number): Observable<HttpResponse<IApiResponse<{ Files: IFile[] }>>> => {
    return this.http.get<IApiResponse<{ Files: IFile[] }>>(
      `/api/v2/file/getlist/${entityId}?apikey=${this.APIKEY}`, { observe: 'response' }
    );
  }

  public deleteFile = (entityId: number, fileName: string): Observable<HttpResponse<null>> => {
    return this.http.delete<null>(
      `/api/v2/file/delete/${entityId}?filename=${fileName}&apikey=${this.APIKEY}`, { observe: 'response' }
    );
  }

  public getFileUrl = (entityId: number, fileName: string): string => {
    return `/api/v2/file/get/${entityId}?filename=${fileName}&apikey=${this.APIKEY}`;
  }

  public uploadDriverPhoto = (
    file: File,
    driverID: number,
  ): Observable<HttpResponse<null>> => {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<null>(
      'API/Drivers/uploadPhoto/' + driverID, formData, { observe: 'response' }
    );
  }

  public generateDriverPhotoURL = (driverID: number | string): string => {
    return [
      'API/Drivers/getPhoto/',
      driverID,
      '/',
      this.APIKEY,
      '?time=',
      Date.now(),
    ].join('');
  }

  /** Gets IDs of available drivers for given array of orders IDs */
  public getAvailableDrivers(
    ordersIDs: Array<number>): Observable<HttpResponse<Array<number>>> {
    return this.http.post<Array<number>>(
      'API/RouteOpt/getAvaliableDrivers', ordersIDs, { observe: 'response' }
    );
  }

  //#endregion Drivers

  //#region Vehicles


  getVehicle = (id: number) =>
    this.http.get<IVehicleFullDto>(`api/v3/vehicle/${id}`);

  postVehicle = (dto: IVehicleFullDto) =>
    this.http.post<IVehicleFullDto>(`api/v3/vehicle`, dto);

  putVehicle = (dto: IVehicleFullDto) =>
    this.http.put<IVehicleFullDto>(`api/v3/vehicle`, dto);

  public getVehicleGroups = (): Observable<HttpResponse<Response<Array<IVehicleGroup>>>> => {
    return this.http.get<Response<Array<IVehicleGroup>>>(
      'api/v2/vehiclegroup/GetList', { observe: 'response' }
    );
  }

  public addVehicleGroup = (
    newGroup: IVehicleGroup,
  ): Observable<HttpResponse<IApiResponse<IVehicleGroup>>> => {
    const body: IVehicleGroupNew = {
      Name: newGroup.Name,
      AccountId: newGroup.AccountId,
      Vehicles: [],
    };
    return this.http.post<IApiResponse<IVehicleGroup>>(
      'API/v2/vehiclegroup/Post', body, { observe: 'response' }
    );
  }

  public updateVehicleGroup = (
    vehicleGroup: IVehicleGroup,
  ): Observable<HttpResponse<IApiResponse<IVehicleGroup>>> => {
    return this.http.put<IApiResponse<IVehicleGroup>>(
      'API/v2/vehiclegroup/Update', vehicleGroup, { observe: 'response' }
    );
  }

  public deleteVehicleGroup = (id: number): Observable<HttpResponse<Response<null>>> => {
    return this.http.delete<Response<null>>(
      'api/v2/vehiclegroup/DeleteById/' + id, { observe: 'response' }
    );
  }

  public getAllVehicles = (params: any = { showAll: true }): Observable<IListData<IVehicle>> => {
    return this.http.get<Response<IListData<IVehicle>>>(
      'API/v3/Vehicle', { observe: 'response', params }
    ).pipe(map(res => res.body.data));
  }

  public getVehiclesShort = (params?: { OutOfService?: boolean, Search?: string, Offset?: number, Limit?: number }) => {
    return this.http.get<IListData<IVehicleShortDto>>(
      'api/v3/vehicles/short', { observe: 'response', params }
    ).pipe(map(res => res.body.data));
  }

  public getVehicleInfo = (
    vehicleID: number,
    includeTrailers: numberBoolean,
    includeRoutesAndShifts: numberBoolean,
  ): Observable<HttpResponse<IVehicleCombined>> => {
    return this.http.get<IVehicleCombined>(
      `API/Vehicles/getInfo/${vehicleID}/${includeTrailers}/${includeRoutesAndShifts}`,
      { observe: 'response' },
    );
  }

  public createVehicle = (
    vehicle: IVehicleFull,
  ): Observable<HttpResponse<IApiResponse<IVehicleFull>>> => {
    const body = {
      ...vehicle,
      AccountId: this.accountID,
    };
    return this.http.post<IApiResponse<IVehicleFull>>(
      'API/v2/Vehicle/CreateVehicle', body, { observe: 'response' }
    );
  }

  public updateVehicle = (
    vehicle: IVehicleFull,
  ): Observable<HttpResponse<''>> => {
    return this.http.post<''>(
      'API/Vehicles/UpdateVehicle', vehicle, { observe: 'response' }
    );
  }

  public deleteVehicle = (
    id: number,
  ): Observable<HttpResponse<Array<IVehicle>>> => {
    return this.http.get<Array<IVehicle>>(
      'API/Vehicles/DeleteVehicle/' + id, { observe: 'response' }
    );
  }

  public setVehicleQrIdentity = (data: { vehicleId: number, qrIdentity: string }[]): Observable<HttpResponse<unknown>> => {
    return this.http.patch<unknown>(
      'api/v3/vehicles/set-qr', data, { observe: 'response' }
    );
  }

/*   public importVehiclesFromXlsx = (
    file: File
  ): Observable<HttpResponse<number>> => {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<number>(
      'api/v3/vehicle/set-import-file', formData, { observe: 'response' }
    );
  } */

  public startXlsVehiclesImport = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<number>('api/v3/vehicle/import', formData);
  };

  public deleteOrderFromVehicle(
    vehicleID: number,
    orderID: number,
  ): Observable<HttpResponse<IVehicleDeleteOrderResponse>> {
    return this.http.get<IVehicleDeleteOrderResponse>(
      `API/Orders/DeleteOrderFromVehicle/${vehicleID}/${orderID}`,
      { observe: 'response' },
    );
  }

  public sendMessageToVehicle = (
    vehicleID: number,
    message: string,
  ): Observable<HttpResponse<null>> => {
    return this.http.post<null>(
      'API/Vehicles/Speak',
      { id: vehicleID, text: message },
      { observe: 'response' },
    );
  }

  public getLiveMapVehicles = (): Observable<ILiveMapVehicleGetDTO[]> => {
    return this.http.get<ILiveMapVehicleGetDTO[]>(
      'api/v3/livemap/vehicles', { observe: 'response' }
    ).pipe(map(res => res.body));
  }

  //#endregion Vehicles

  //#region Customers

  public getCustomersByName = (
    customerName: string
  ): Observable<HttpResponse<ICustomerSearchAnswer>> => {
    return this.http.post<ICustomerSearchAnswer>(
      'API/Clients/SearchClients', customerName, { observe: 'response' }
    );
  }

  public importCustomersFromXlsx = (
    file: File
  ): Observable<HttpResponse<number>> => {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<number>(
      'API/Clients/importClients', formData, { observe: 'response' }
    );
  }

  public getCustomer = (id: number): Observable<HttpResponse<ICustomerFull>> => {
    return this.http.post<ICustomerFull>(
      'API/Clients/getClient', id, { observe: 'response' }
    );
  }

  public getUnitsLog = (
    customerID: number
  ): Observable<HttpResponse<Array<IUnitLogRecord>>> => {
    return this.http.post<Array<IUnitLogRecord>>(
      'API/MeasureUnits/getUnitsLog',
      { client_id: customerID },
      { observe: 'response' },
    );
  }

  public addCustomer = (
    customer: ICustomerFull
  ): Observable<HttpResponse<ICustomerFull>> => {
    return this.http.post<ICustomerFull>(
      'API/Clients/AddClient', customer, { observe: 'response' }
    );
  }

  public updateCustomer = (
    customer: ICustomerFull
  ): Observable<HttpResponse<null>> => {
    return this.http.post<null>(
      'API/Clients/UpdateClient', customer, { observe: 'response' }
    );
  }

  public deleteCustomer = (
    customerID: number
  ): Observable<HttpResponse<null>> => {
    return this.http.post<null>(
      'API/Clients/deleteClient/', customerID, { observe: 'response' }
    );
  }

  // TO DO should be replaced with special method [SB-141]
  public getCustomerOrders = (
    customerName: string,
  ): Observable<HttpResponse<Array<IOrder>>> => {
    return this.searchOrders(customerName);
  }

  //#endregion Customers

  //#region Orders

  public searchOrders = (
    searchStr: string,
  ): Observable<HttpResponse<Array<IOrder>>> => {
    return this.http.post<Array<IOrder>>(
      'API/Orders/searchOrders', searchStr, { observe: 'response' }
    );
  }

  public searchOrdersByName = (
    searchStr: string,
  ): Observable<HttpResponse<Array<IOrder>>> => {
    return this.http.post<Array<IOrder>>(
      'API/Orders/searchOrdersByName', searchStr, { observe: 'response' }
    );
  }

  public getDashboardOrders = (
    parameters: IOrdersGetParameters,
  ): Observable<HttpResponse<IOrdersResponse>> => {
    return this.http.post<IOrdersResponse>(
      'API/Orders/getDashboardOrders', parameters, { observe: 'response' }
    );
  }

  public getLiveMapOrders = (
    params: ILiveMapOrdersParams,
  ): Observable<IUnassignedOrder[]> => {
    return this.http.get<Response<IUnassignedOrder[]>>(
      'api/v3/livemap/orders', { observe: 'response', params: { ...params } }
    ).pipe(map((res) => res.body.data));
  }

  public getOrders = (
    parameters: IOrdersGetParameters,
  ): Observable<HttpResponse<IOrdersResponse>> => {
    return this.http.post<IOrdersResponse>(
      'API/Orders/getMainOrders', parameters, { observe: 'response' }
    );
  }

  public linkOrdersToManifest = (
    request: IOrdersToManifestRequest,
  ): Observable<HttpResponse<null>> => {
    return this.http.post<null>(
      'API/OrdersManifests/linkOrdersToManifest', request, { observe: 'response' }
    );
  }

  public deleteOrders = (
    IDs: Array<number | string>
  ): Observable<HttpResponse<null>> => {
    return this.http.post<null>(
      'API/Orders/deleteOrders', IDs, { observe: 'response' }
    );
  }

  public addManifestsFromWizard = (
    manifests: Array<IManifestForAddRequest>,
  ): Observable<HttpResponse<IManifestsAddResponse>> => {
    return this.http.post<IManifestsAddResponse>(
      'API/OrdersManifests/AddManifestsFromWizard',
      manifests,
      { observe: 'response' },
    );
  }

  public setRouteOptimizationRequest = (
    optimizationRequest: IRouteOptimizationRequest,
  ): Observable<HttpResponse<null>> => {
    return this.http.post<null>(
      'API/RouteOpt/setRouteOptRequest',
      optimizationRequest,
      { observe: 'response' },
    );
  }

  public getOrder = (
    orderID: number | string,
  ): Observable<HttpResponse<IOrderResponse>> => {
    return this.http.get<IOrderResponse>(
      'API/Orders/getOrder/' + orderID, { observe: 'response' }
    );
  }

  public saveOrder = (
    order: IOrderFull,
  ): Observable<HttpResponse<IOrderFull>> => {
    return this.http.post<IOrderFull>(
      'API/Orders/saveOrder', order, { observe: 'response' }
    );
  }

  public deleteOrder = (
    orderID: number | string,
  ): Observable<HttpResponse<null>> => {
    return this.http.get<null>(
      'API/Orders/DeleteOrder/' + orderID, { observe: 'response' }
    );
  }

  public bulkDuplicateOrder = (
    data: IBulkDuplicateOrderRequest
  ): Observable<HttpResponse<null>> => {
    return this.http.post<null>(
      'api/v3/order/duplicate', data, { observe: 'response' }
    );
  }

  public sendOrderNotification = (
    orderID: number,
  ): Observable<HttpResponse<null>> => {
    return this.http.get<null>(
      'API/Orders/SendNotification/' + orderID, { observe: 'response' }
    );
  }

  public setMoneyReceived = (
    orderID: number,
  ): Observable<HttpResponse<null>> => {
    return this.http.get<null>(
      'API/Orders/setMoneyReceived/' + orderID, { observe: 'response' }
    );
  }

  public generateSignatureURL = (
    orderID: number | string,
    type: 'Pickup' | 'Delivery',
  ): string => {
    return [
      'API/orders/Get',
      type,
      'Signature/',
      orderID,
      '/',
      this.APIKEY,
    ].join('');
  }

  public generateOrderPdfURL = (orderID: number): string => {
    return `API/orders/getConsPDF/${orderID}/${this.APIKEY}`;
  }

  public allocateOrderToManifestSourceLink = (orderID: number): string => {
    return 'API/RouteOpt/allocateOrder/' + orderID;
  }

  public autoallocateSourceLink = (): string => {
    return 'API/RouteOpt/calcShifts';
  }

/*   public uploadOrdersImportFile = (
    file: File
  ): Observable<HttpResponse<null>> => {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<null>(
      'API/Orders/setImportFile', formData, { observe: 'response' }
    );
  }
 */
  public startCsvOrdersImport = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<number>('API/Orders/importOrders', formData);
  };

  public startXlsOrdersImport = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<number>('API/Orders/importXlsOrders', formData);
  };

  public getMyobCompanies = (): Observable<HttpResponse<string>> => {
    return this.http.get<string>(
      'API/myob/getCompanyFiles', { observe: 'response' }
    );
  }

  public getOrderMentumProperties = (): Observable<HttpResponse<{ data?: { propertyList?: Array<string> } }>> => {
    return this.http.get<{ data?: { propertyList?: Array<string> } }>(
      'API/Orders/orderMentumProperties', { observe: 'response' }
    );
  }

  private dateForSourceLink = (
    importData: IImportOrdersDialogOutput,
    provider?: EOrderImportOptions,
  ): string => {
    switch (provider) {
      case EOrderImportOptions.JIWA:
        return `from_date=${importData.fromDate}&to_date=${importData.toDate}`;
      case EOrderImportOptions.MYOB:
        return `from_date=${importData.fromDate}&to_date=${importData.toDate}`;
      case EOrderImportOptions.Fresho:
        return `startDate=${importData.fromDate}&endDate=${importData.toDate}`;
      default:
        return `date_from=${importData.fromDate}&date_to=${importData.toDate}`;
    }
  }

  public startJiwaOrderImport = (
    importData: IImportOrdersDialogOutput
  ) => this.http.get<number>([
    'API/Orders/importFromJiwa?',
    this.dateForSourceLink(importData, EOrderImportOptions.JIWA),
    '&create_manifests=',
    importData.createManifests,
  ].join(''));

  public startMyobOrdersImport = (
    importData: IImportOrdersDialogOutput
  ) => this.http.get<number>([
    'API/myob/getInvoices?',
    this.dateForSourceLink(importData, EOrderImportOptions.MYOB),
    '&multi_stage_import=',
    importData.multiStage,
    '&create_manifests=',
    importData.createManifests,
    '&uri=',
    encodeURIComponent(importData.myobURI),
  ].join(''));

  public startShopifyOrdersImport = (
    importData: IImportOrdersDialogOutput
  ) => this.http.get<number>([
    'API/Orders/importFromShopify?',
    this.dateForSourceLink(importData),
  ].join(''));

  public startWoodwardsOrdersImport = (
    importData: IImportOrdersDialogOutput
  ) => this.http.get<number>([
    'API/Orders/importFromWoodwards?',
    this.dateForSourceLink(importData),
  ].join(''));


  public startAccredoOrdersImport = (
    importData: IImportOrdersDialogOutput
  ) => this.http.get<number>([
    'API/Orders/acreedoImport?',
    this.dateForSourceLink(importData),
  ].join(''));


  public startWooCommerceOrdersImport = () => this.http.get<number>('API/Orders/importFromWooCommerce');
  public startMixVehiclesImport = () => this.http.get<number>('API/v2/Vehicle/ImportFromMix');
  public startMixDriverImport = ()=> this.http.get<number>('API/v2/Driver/ImportFromMix');

  public startMentumOrdersImport = (
    importData: IImportOrdersDialogOutput
  ) => this.http.get<number>([
    'API/Orders/importFromOrderMentum?',
    this.dateForSourceLink(importData),
    '&create=',
    importData.createManifests,
    '&property=',
    importData.filterProperty,
  ].join(''));

  public startDynamicsOrdersImport = (
    importData: IImportOrdersDialogOutput
  ) => this.http.get<number>([
    'API/Orders/importFromDynamicsPlastics?',
    this.dateForSourceLink(importData),
  ].join(''));

  public getImportStreamLink = (id: number): string => `api/v3/importhistory/${id}/stream`;

  public cancelImport = (id: number) => this.http.post(`api/v3/importhistory/${id}/cancel`, null);

  public startFreshoOrdersImport = (importData: IImportOrdersDialogOutput) =>
    this.http.get<number>('api/v3/order/start-fresho-import?' + this.dateForSourceLink(importData, EOrderImportOptions.Fresho));

  public getOrderPictureURL = (
    orderID: number,
    pictureTimeStamp: string,
    preview = false,
  ) => {
    return [
      'API/orders/GetPicture',
      orderID,
      pictureTimeStamp,
      preview ? 1 : 0,
      this.APIKEY,
    ].join('/');
  }

  public getOrderPicture = (
    orderID: number,
    pictureTimeStamp: string,
  ): Observable<HttpResponse<Blob>> => {
    return this.http.get(
      this.getOrderPictureURL(orderID, pictureTimeStamp),
      { observe: 'response', responseType: 'blob' }
    );
  }

  public getPicture = (url: string): Observable<HttpResponse<Blob>> => {
    return this.http.get(url, { observe: 'response', responseType: 'blob' });
  }

  public bulkOrderSettingsUpdate = (
    data: BulkUpdateOrderRequestData
  ): Observable<HttpResponse<unknown>> => {
    return this.http.put<unknown>(
      '/api/v3/order/bulk-update-settings ', data, { observe: 'response' }
    );
  }
  //#endregion Orders

  //#region Manifests
  public getManifests = (): Observable<HttpResponse<Array<IManifest>>> => {
    return this.http.get<Array<IManifest>>(
      'API/OrdersManifests/getManifests', { observe: 'response' }
    );
  }

  public getManifestList = (tableParams: any): Observable<IListData<IManifest>> => {
    const params = { ...tableParams, accountId: this.accountID };
    return this.http.get<IListData<IManifest>>(
      'api/v3/manifest', { observe: 'response', params }
    ).pipe(map((res) => res.body));
  }

  public getManifestsWithOrders = (
    date_filter: Date | null,
  ): Observable<HttpResponse<Array<IManifestFull>>> => {
    return this.http.post<Array<IManifestFull>>(
      'API/OrdersManifests/getManifestsWithOrders', date_filter, { observe: 'response' }
    );
  }

  public getManifest = (
    manifestID: number | string,
  ): Observable<HttpResponse<IManifestFull>> => {
    return this.http.get<IManifestFull>(
      'API/OrdersManifests/GetManifest/' + manifestID, { observe: 'response' }
    ).pipe(map(res => {
      res.body.orders = res.body.orders.map(order => {
        order.capacity_name = order.capacity_name || res.body.CapacityName;
        return order;
      })
      return res;
    }))
  }

  public getRealRouteCoords = (id: string): Observable<IManifestCoords> => {
    return this.httpWithoutLoader.get<IManifestCoords>(`/api/v3/manifest/${id}/coords`, { observe: 'response' }).pipe(
      map(res => res.body)
    );
  }

  saveHeartBeat(id: number, dto: {
    driverId?: number,
    vehicleId?: number,
    accountId?: number,
    userId?: number,
    latitude: number,
    longitude: number,
    orderId: number,
    orderStatusId?: number
  }) {
    return this.http.put<void>(`api/v3/manifest/heartbeat/${id}`, dto).toPromise();
  }

  public getManifestWithOrders = (
    manifestID: number | string,
  ): Observable<HttpResponse<IManifestFull>> => {
    return this.http.get<IManifestFull>(
      'API/OrdersManifests/GetManifestWithOrders/' + manifestID, { observe: 'response' }
    );
  }

  public addManifest = (
    manifestName: string,
    manifestDate: string,
    realTimeRouteVisible: boolean,
  ): Observable<HttpResponse<IManifestFull>> => {
    return this.http.post<IManifestFull>(
      'API/OrdersManifests/NewManifest', { manifestName, manifestDate, realTimeRouteVisible }, { observe: 'response' }
    );
  }

  public saveManifest = (
    manifest: IManifest,
  ): Observable<HttpResponse<string>> => {
    return this.http.post<string>(
      'API/OrdersManifests/SaveManifest', manifest, { observe: 'response' }
    );
  }

  public renameManifest = (
    manifestID: number,
    newName: string,
  ): Observable<HttpResponse<null>> => {
    return this.http.post<null>(
      'API/OrdersManifests/RenameManifest',
      { id: manifestID, name: newName },
      { observe: 'response' },
    );
  }

  public modifyManifest = (
  payload: {
    ManifestId: number;
    ManifestName?: string;
    ManifestDate?: string;
    VehicleId?: number;
    RemoveVehicle?: boolean;
    DriverId?: number;
    RemoveDriver?: boolean;
  },
  ): Observable<HttpResponse<null>> => {
    return this.http.patch<null>(
      'API/v3/manifest/modify',
      payload,
      { observe: 'response' },
    );
  }


  public linkVehicleAndDriverToManifest = (
    manifestLinkingRequest: IManifestLinkingRequest,
  ): Observable<HttpResponse<IManifestLinkingResponse>> => {
    return this.http.post<IManifestLinkingResponse>(
      'API/OrdersManifests/LinkManifestToVehicle',
      manifestLinkingRequest,
      { observe: 'response' },
    );
  }

  public deleteManifest = (
    manifestID: number,
  ): Observable<HttpResponse<Array<IManifest>>> => {
    return this.http.get<Array<IManifest>>(
      'API/OrdersManifests/DeleteManifest/' + manifestID, { observe: 'response' }
    );
  }

  public sendManifestToVehicle = (
    manifestID: number,
  ): Observable<HttpResponse<IManifestToVehicleResponse>> => {
    return this.http.get<IManifestToVehicleResponse>(
      'API/OrdersManifests/SendManifestToVehicle/' + manifestID,
      { observe: 'response' },
    );
  }

  public reverseManifest = (
    manifestID: number,
  ): Observable<HttpResponse<IManifest>> => {
    return this.http.get<IManifest>(
      'API/OrdersManifests/ReverseManifest/' + manifestID,
      { observe: 'response' },
    );
  }

  public duplicateManifests = (
    request: IManifestDuplicateRequest,
  ): Observable<HttpResponse<Array<IManifest>>> => {
    return this.http.post<Array<IManifest>>(
      'API/OrdersManifests/duplicateManifests', request, { observe: 'response' }
    );
  }

  public splitManifests = (
    request: IManifestSplitRequest,
  ): Observable<HttpResponse<string>> => {
    return this.http.post<string>(
      'API/OrdersManifests/splitManifests', request, { observe: 'response' }
    );
  }

  public deleteManifests = (
    request: IManifestDeleteRequest,
  ): Observable<HttpResponse<string>> => {
    return this.http.post<string>(
      'API/OrdersManifests/deleteManifests', request, { observe: 'response' }
    );
  }

  public sendManifestsToVehicle = (
    manifestList: Array<number>,
  ): Observable<HttpResponse<IManifestsToVehicleResponse>> => {
    return this.http.post<IManifestsToVehicleResponse>(
      'API/OrdersManifests/SendManifestsToVehicle/',
      manifestList,
      { observe: 'response' },
    );
  }

  public sendManifestsNotifications = (
    request: IManifestNotifyRequest,
  ): Observable<HttpResponse<string>> => {
    return this.http.post<string>(
      'API/Orders/SendBulkNotifications/', request, { observe: 'response' }
    );
  }

  public drivers = (search?: string, take?: number) => this.http.get<Response<{ id: number, fullName: string }[]>>('/api/v3/drivers/short', { params: purge({ search, take }) });


  public getReportDataSource = (reportName: string, params: Record<string, any>) => {
    return this.http.get<IListData<any>>(`/api/v3/report/${reportName}/dataSource`, { params: purge(params) });
  }

  public generateManifestsXlsxURL = (manifestIDs: Array<number>): string => {
    return [
      '/ReportViewer.aspx?report=ManifestReport.xlsx&ids=',
      manifestIDs.join('|'),
    ].join('');
  }

  public generateManifestsPdfURL = (manifestIDs: Array<number>): string => {
    return 'API/orders/PrintManifestSummaries/' + manifestIDs.join('|');
  }

  public generateManifestsConsNotesPdfURL = (manifestIDs: Array<number>): string => {
    return 'API/orders/PrintManyManifestConsNotes/' + manifestIDs.join('|');
  }

  public generateManifestXlsxURL = (manifestID: number): string => {
    return [
      '/ReportViewer.aspx?report=ManifestReport.xlsx&id=',
      manifestID,
    ].join('');
  }

  public generateManifestPdfURL = (manifestID: number): string => {
    return 'API/orders/PrintManifestSummary/' + manifestID;
  }

  public generateManifestConsNotesPdfURL = (manifestID: number): string => {
    return 'API/orders/PrintManifestConsNotes/' + manifestID;
  }

  public createLiveMapManifest = (manifest: ILiveMapManifestCreate): Observable<ILiveMapManifest> => {
    return this.http.post<Response<ILiveMapManifest>>(
      'api/v3/livemap/manifests', manifest, { observe: 'response' }
    ).pipe(map((res) => res.body.data));
  }

  public getLiveMapManifests = (
    params: ILiveMapManifestsParams,
  ): Observable<ILiveMapManifest[]> => {
    return this.http.get<Response<ILiveMapManifest[]>>(
      'api/v3/livemap/manifests', { observe: 'response', params: { ...params } }
    ).pipe(map((res) => res.body.data));
  }

  public getLiveMapManifest = (
    manifestID: number | string,
  ): Observable<HttpResponse<ILiveMapManifest>> => {
    return this.http.get<ILiveMapManifest>(
      'api/v3/livemap/manifests/' + manifestID, { observe: 'response' }
    );
  }

  public updateLiveMapManifests = (
    data: ILiveMapManifestUpdate,
  ): Observable<HttpResponse<ILiveMapManifest[]>> => {
    return this.http.post<ILiveMapManifest[]>(
      'api/v3/livemap/updateOrdersManifests', data, { observe: 'response' }
    );
  }

  //#endregion Manifests

  //#region Zones

  public getAllZones = (): Observable<HttpResponse<Array<IZone>>> => {
    return this.http.get<Array<IZone>>(
      'API/Zones/GetAllZones', { observe: 'response' }
    );
  }

  public saveAllZones = (zones: Array<IZone>): Observable<HttpResponse<null>> => {
    return this.http.post<null>(
      'API/Zones/SaveAllZones', zones, { observe: 'response' }
    );
  }

  public addZone = (zone: IZone): Observable<HttpResponse<Array<IZone>>> => {
    return this.http.post<Array<IZone>>(
      'API/Zones/addZone', zone, { observe: 'response' }
    );
  }

  public saveZone = (zone: IZone): Observable<HttpResponse<Array<IZone>>> => {
    return this.http.post<Array<IZone>>(
      'API/Zones/saveZone', zone, { observe: 'response' }
    );
  }

  public deleteZone = (zone: IZone): Observable<HttpResponse<Array<IZone>>> => {
    return this.http.post<Array<IZone>>(
      'API/Zones/deleteZone', zone, { observe: 'response' }
    );
  }

  //#endregion Zones

  //#region Accounts

  public getCapacityTypes = (): Observable<HttpResponse<Response<Array<ICapacityType>>>> => {
    return this.http.get<Response<Array<ICapacityType>>>(
      'API/v2/Account/GetCapacityTypes', { observe: 'response' }
    );
  }

  public getMapServers = (): Observable<HttpResponse<Response<Array<IMapServer>>>> => {
    return this.http.get<Response<Array<IMapServer>>>(
      'API/v2/Account/GetMapServers', { observe: 'response' }
    );
  }

  public getConsignmentNotes = (): Observable<HttpResponse<Response<Array<IConsignmentNote>>>> => {
    return this.http.get<Response<Array<IConsignmentNote>>>(
      'API/v2/Account/GetConsignmentNotes', { observe: 'response' }
    );
  }

  public getAccountParents = (): Observable<HttpResponse<Response<Array<IAccountParent>>>> => {
    return this.http.get<Response<Array<IAccountParent>>>(
      'API/v2/Account/GetParents', { observe: 'response' }
    );
  }

  public getAccountList = (): Observable<HttpResponse<Response<Array<IAccountSummary>>>> => {
    return this.http.get<Response<Array<IAccountSummary>>>(
      'API/v2/Account/GetList', { observe: 'response' }
    );
  }

  public getAccountDetails = (
    accountID: number | string,
  ): Observable<HttpResponse<Response<IAccountDetail>>> => {
    return this.http.get<Response<IAccountDetail>>(
      'API/v2/Account/GetById/' + accountID, { observe: 'response' }
    );
  }

  public saveAccount = (
    account: IAccountDetail,
  ): Observable<HttpResponse<Response<IAccountDetail>>> => {
    /** adds necessary fields for backend */
    const accountTemp = {
      ...account,
      name: account.Name,
      id: account.Id || -1,
    };
    return this.http.post<Response<IAccountDetail>>(
      'API/v2/Account/Save', accountTemp, { observe: 'response' }
    );
  }

  public saveAccountModules = (
    accountModules: Array<IAccountModule>,
  ): Observable<HttpResponse<Response<Array<IAccountModule>>>> => {
    return this.http.post<Response<Array<IAccountModule>>>(
      'API/v2/module/SaveAccountModules', accountModules, { observe: 'response' }
    );
  }

  public generateNewApiKey = (
    accountID: number | string,
  ): Observable<HttpResponse<IAccountFull>> => {
    return this.http.post<IAccountFull>(
      'API/GenAPIKey', Number(accountID), { observe: 'response' }
    );
  }

  public generateAccountLogoURL = (accountID: number | string): string => {
    return [
      'API/Account/getLogo/',
      accountID,
      '?',
      Date.now(),
      '&APIKEY=',
      this.APIKEY,
    ].join('');
  }

  public generateLogoURL = (APIKey: string): string => {
    return [
      'API/Account/getLogo/',
      APIKey,
      '?',
      Date.now(),
    ].join('');
  }

  public getAccountModules = (
    accountID?: string | number
  ): Observable<HttpResponse<Response<Array<IAccountModule>>>> => {
    return this.http.get<Response<Array<IAccountModule>>>(
      'API/v2/module/GetAccountModules/' + (accountID === NEW_ID ? 0 : accountID), { observe: 'response' }
    );
  }

  //#endregion Accounts

  //#region Users

  public getUserModules = (
    userID?: string | number
  ): Observable<HttpResponse<Response<Array<IUserModule>>>> => {
    return this.http.get<Response<Array<IUserModule>>>(
      'API/v2/module/GetUserModules/' + (userID === NEW_ID ? 0 : userID), { observe: 'response' }
    );
  }

  public saveUserModules = (
    userModules: Array<IUserModule>,
  ): Observable<HttpResponse<Response<Array<IUserModule>>>> => {
    return this.http.post<Response<Array<IUserModule>>>(
      'API/v2/module/SaveUserModules', userModules, { observe: 'response' }
    );
  }

  public getAccountUserList = (
    accountID: number | string,
  ): Observable<HttpResponse<Response<Array<IUserSummary>>>> => {
    return this.http.get<Response<Array<IUserSummary>>>(
      'API/v2/Account/GetAllUsers/' + accountID, { observe: 'response' }
    );
  }

  public getUserTypes = (): Observable<HttpResponse<Response<Array<IUserType>>>> => {
    return this.http.get<Response<Array<IUserType>>>(
      'API/v2/Account/GetUserTypes', { observe: 'response' }
    );
  }

  public getUser = (
    AccountID: number | string,
    UserID: number | string,
  ): Observable<HttpResponse<Response<IUserDetail>>> => {
    return this.http.get<Response<IUserDetail>>(
      'API/v2/Account/GetUserById/' + AccountID + '/' + UserID, { observe: 'response' }
    );
  }

  // TO DO rework after new backend API point [SB-172]
  public saveUser = (
    user: IUserDetail,
  ): Observable<HttpResponse<Response<IUserDetail>>> => {
    return this.http.post<Response<IUserDetail>>(
      'API/v2/Account/SaveUser', user, { observe: 'response' }
    );
  }

  public deleteUser = (
    userID: number | string
  ): Observable<HttpResponse<Array<IAccount>>> => {
    return this.http.get<Array<IAccount>>(
      'API/Accounts/delUser/' + userID, { observe: 'response' }
    );
  }

  //#endregion Users

  //#region Reports

  public generateReportURL = (
    params: { [k: string]: any }
  ): string => '/ReportViewer.aspx?' + Object.entries(params).map(([k, v]) => `${k}=${v}`).join('&');


  public getLoadTypes = () => this.http.get<LoadTypeDto[]>('api/v3/report/load-types');

  //#endregion Reports

  //#region SafetyInspections

  public getSafetyInspections = (userID?: number): Observable<HttpResponse<Response<Array<ISafetyInspectionBase>>>> => {
    const userSearchParam = userID ? `&AllocatedUserId=${userID}` : '';
    return this.http.get<Response<Array<ISafetyInspectionBase>>>(
      'API/v2/inspection/GetList?VehicleId=0&SortBy=inspectiondate' + userSearchParam, { observe: 'response' }
    );
  }

  public getSafetyInspection = (inspectionId: number): Observable<HttpResponse<Response<ISafetyInspection>>> => {
    return this.http.get<Response<ISafetyInspection>>(
      'API/v2/inspection/GetById/' + inspectionId, { observe: 'response' }
    );
  }

  public updateSafetyInspection = (data: ISafetyInspection): Observable<HttpResponse<Response<ISafetyInspection>>> => {
    return this.http.put<Response<ISafetyInspection>>(
      'API/v2/inspection/Update/', data, { observe: 'response' }
    );
  }

  public getRepairersList = (): Observable<HttpResponse<Response<Array<ISafetyRepairer>>>> => {
    return this.http.get<Response<Array<ISafetyRepairer>>>(
      'api/v2/user/GetList?OnlyRepairers=1', { observe: 'response' }
    );
  }

  public getSafetyInfo = (): Observable<HttpResponse<Response<{ UserId: number }>>> => {
    return this.http.get<Response<{ UserId: number }>>(
      'api/v2/info/Get', { observe: 'response' }
    );
  }

  public getAllVehiclesForSafety = (): Observable<HttpResponse<Response<Array<ISafetyVehicleListItem>>>> => {
    return this.http.get<Response<Array<ISafetyVehicleListItem>>>(
      'api/v2/vehicle/GetList?allVehicles=false', { observe: 'response' }
    );
  }

  //#endregion SafetyInspections

  public uploadSafetyImages = (imageFiles: Array<File>): Observable<Array<string>> => {
    return of(...imageFiles).pipe(
      mergeMap((file) => this.uploadSafetyImage(file).pipe(
        map((requestResult) => requestResult.body.data.Name),
        catchError(() => of(null)),
      ), 1),
      reduce((total: Array<string>, result: string | null) => {
        return total.concat(result);
      }, []),
    );
  }

  public uploadSafetyImage = (file: File): Observable<HttpResponse<Response<ISafetyImage>>> => {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('APIKey', this.APIKEY);
    return this.http.post<Response<ISafetyImage>>(
      'API/v2/image/upload', formData, { observe: 'response' }
    );
  }

  //#region SafetyItems

  public getSafetyItems = (): Observable<HttpResponse<Response<Array<ISafetyItem>>>> => {
    return this.http.get<Response<Array<ISafetyItem>>>(
      'API/v2/safetyitem/GetItems', { observe: 'response' }
    );
  }

  public addSafetyItem = (
    safetyItem: ISafetyItem
  ): Observable<HttpResponse<IApiResponse<ISafetyItem>>> => {
    const tempItem = {
      Id: 0,
      AccountId: this.accountID,
      Title: safetyItem.Title,
      Instruction: safetyItem.Instruction,
      ImageName: safetyItem.ImageName,
      IsCritical: safetyItem.IsCritical ? 1 : 0,
      IsDisabled: 0,
    };

    return this.http.post<IApiResponse<ISafetyItem>>(
      'API/v2/safetyitem/PostItem/', tempItem, { observe: 'response' }
    );
  }

  public saveSafetyItem = (safetyItem: ISafetyItem): Observable<HttpResponse<IApiResponse<ISafetyItem>>> => {
    const tempItem = {
      Id: safetyItem.Id,
      AccountId: safetyItem.AccountId,
      Title: safetyItem.Title,
      Instruction: safetyItem.Instruction,
      ImageName: safetyItem.ImageName,
      IsCritical: safetyItem.IsCritical ? 1 : 0,
      IsDisabled: safetyItem.IsDisabled ? 1 : 0,
    };

    return this.http.put<IApiResponse<ISafetyItem>>(
      'API/v2/safetyitem/UpdateItem', tempItem, { observe: 'response' }
    );
  }

/*   public importSafetyItemFromXlsx = (
    file: File
  ): Observable<HttpResponse<number>> => {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<number>(
      '/api/v3/safetyitem/set-import-file', formData, { observe: 'response' }
    );
  }
 */
  public startXlsSafetyItemsImport = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<number>('/api/v3/safetyitem/import', formData);
  };

  public getSafetyItemsGroups = (): Observable<HttpResponse<Response<Array<ISafetyItemsGroup>>>> => {
    return this.http.get<Response<Array<ISafetyItemsGroup>>>(
      'api/v3/safetygroups', { observe: 'response' }
    );
  }

  public addSafetyItemsGroup = (data: ISafetyItemsGroupRequest): Observable<HttpResponse<Response<ISafetyItemsGroup>>> => {
    return this.http.post<Response<ISafetyItemsGroup>>(
      'api/v3/safetygroups', data, { observe: 'response' }
    );
  }

  public updateSafetyItemsGroup = (data: ISafetyItemsGroupRequest): Observable<HttpResponse<Response<ISafetyItemsGroup>>> => {
    return this.http.put<Response<ISafetyItemsGroup>>(
      `api/v3/safetygroups/${data.id}`, data, { observe: 'response' }
    );
  }

  public deleteSafetyItemsGroup = (id: number): Observable<HttpResponse<Response<null>>> => {
    return this.http.delete<Response<null>>(
      `api/v3/safetygroups/${id}`, { observe: 'response' }
    );
  }
  //#endregion SafetyItems

  //#region SafetyPlans
  public getSafetyPlans = (): Observable<HttpResponse<Response<Array<ISafetyPlanBase>>>> => {
    return this.http.get<Response<Array<ISafetyPlanBase>>>(
      'API/v2/plan/GetList', { observe: 'response' }
    );
  }

  public getSafetyPlan = (planId: number): Observable<HttpResponse<Response<ISafetyPlan>>> => {
    return this.http.get<Response<ISafetyPlan>>(
      `API/v2/plan/GetById/${planId}?includeVehicles=true&showAllItems=true`, { observe: 'response' }
    );
  }

  public saveSafetyPlan = (plan: ISafetyPlanToSave): Observable<HttpResponse<Response<ISafetyPlan>>> => {
    return this.http.post<Response<ISafetyPlan>>(
      `API/v2/plan/Post`, plan, { observe: 'response' }
    );
  }

  public updateSafetyPlan = (plan: ISafetyPlanToSave): Observable<HttpResponse<Response<ISafetyPlan>>> => {
    return this.http.put<Response<ISafetyPlan>>(
      `API/v2/plan/Update`, plan, { observe: 'response' }
    );
  }
  //#endregion SafetyPlans

  //#endregion Images
  public getImageUrlByFileId = (fileId: string): string => {
    return `/api/v2/image/get/${fileId}?apikey=${this.APIKEY}`;
  }
  //#endregion Images

  //#region Analytics
  public getFeedbackStatsChart = (params: StatsRequestParams): Observable<Response<FeedbackStats>> => {
    return this.http.get<Response<FeedbackStats>>(
      'api/v3/analytics/feedback/stats', { observe: 'response', params: params as HttpParams }
    ).pipe(map(res => res.body));
  }

  public getFeedbackTrendsChart = (params: TrendsRequestParams): Observable<Response<FeedbackTrends>> => {
    return this.http.get<Response<FeedbackTrends>>(
      'api/v3/analytics/feedback/trends', { observe: 'response', params: params as HttpParams }
    ).pipe(map(res => res.body));
  }

  public getFeedback = (params: FeedbackRequestParams): Observable<Response<IListData<Feedback>>> => {
    return this.http.get<Response<IListData<Feedback>>>(
      'api/v3/analytics/feedback', { observe: 'response', params: params as HttpParams }
    ).pipe(map(res => res.body));
  }
  //#endregion Analytics

  //#region Imports
  public getImportHistory = (params?: { offset?: number, limit?: number, limitByType?: number }): Observable<Response<IImportHistory<null>[]>> => {
    return this.httpWithoutLoader.get<Response<IImportHistory<null>[]>>(
      'api/v3/importhistory', { observe: 'response', params }
    ).pipe(map(res => res.body));
  }

  public getImportHistoryById = (id: number): Observable<Response<IImportHistory<IImportHistoryDetails>>> => {
    return this.httpWithoutLoader.get<Response<IImportHistory<IImportHistoryDetails>>>(
      'api/v3/importhistory/' + id, { observe: 'response' }
    ).pipe(map(res => res.body));
  }
  //#endregion Imports
}

@Injectable()
export class HttpInterceptorApi implements HttpInterceptor {

  constructor(
    private api: ApiService,
    private snackBar: MatSnackBar,
  ) { }

  intercept<T>(
    request: HttpRequest<HttpEvent<T>>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    this.api.requestQuantity++;
    this.api.loading = true;

    const _headers = new HttpHeaders();
    _headers.append('Content-Type', 'application/json');

    if (request.body instanceof FormData) {
      _headers.append('enctype', 'multipart/form-data');
    }
    request = request.clone({
      headers: _headers
    });

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const formMessage = (): string => {
          if (err?.headers?.get('Content-Type')?.includes('text/html')) {
            return 'Unexpected error';
          } else if (Array.isArray(err?.error?.errors)) {
            const errorsText = err?.error?.errors.map(error => {
              if (error.name && error.error) {
                return `\n${error.name} - ${error.error}`;
              } else if (typeof error === 'string') {
                return '\n' + error;
              } else {
                return '';
              }
            }).join('');
            return `${err.error.message}:${errorsText}`;
          }
          else if (err?.error?.message) {
            return err.error.message;
          }
          else if (err?.error?.Message) {
            return err.error.Message;
          } else {
            return err?.error;
          }
        };

        const errorHeader = err?.statusText || err?.message || 'Unknown error';
        const errorMessage = formMessage();

        const fullMessage = (errorMessage
          ? `${errorHeader}: ${errorMessage}`
          : errorHeader
        );

        // TODO Remove when the following endpoints don't respond with 400 in case of emply array
        const no400ErrorUrls = ['API/v2/plan/GetPlans'];
        const supressMessage = no400ErrorUrls.indexOf(request.url) > -1 && err.status === 400;

        if (!supressMessage) {
          this.snackBar.open(fullMessage, '', {
            duration: ETimers.Long,
            panelClass: ['snackBarError'],
          });
        }

        return throwError(err);
      }),
      finalize(() => {
        this.api.requestQuantity--;
        if (this.api.requestQuantity === 0) {
          this.api.loading = false;
        }
      }),
    );
  }
}


export function purge(params: object): Record<string, any> {
  return Object.entries(params)
    .filter(([, v]) => v !== null && v !== undefined)
    .reduce((p, [k, v]) => { p[k] = v; return p }, {});
}

import { Component, Input, OnInit } from '@angular/core';
import { IMPORT_HISTORY_STATUSES_MAP } from 'app/consts/constants';
import { ECustomIcons, EImportStatus, ETextClasses } from 'app/consts/enums';

@Component({
  selector: 'fuse-import-status-icon[status]',
  templateUrl: './import-status-icon.component.html',
  styleUrls: ['./import-status-icon.component.scss']
})
export class ImportStatusIconComponent implements OnInit {
  @Input() status: EImportStatus;
  @Input() imported: number;

  icon: {
    icon: ECustomIcons;
    color: ETextClasses;
    text: string;
  }

  ngOnInit(): void {
    const statusesInfo = IMPORT_HISTORY_STATUSES_MAP;
    if (this.status === EImportStatus.Finished && this.imported === 0) {
      this.icon = {
        icon: ECustomIcons.WarningCircleFilled,
        color: ETextClasses.Grey,
        text: statusesInfo[EImportStatus.Finished].text,
      }
    } else {
      this.icon = statusesInfo[this.status];
    }
  }

  public isProgress = (): boolean => {
    return this.status === EImportStatus.Process;
  }
}

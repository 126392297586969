<div
  *ngIf="isVisible"
  class="import-info"
>
  <div class="import-info__header">
    <div class="header__title">
      <div>Import</div>
      <fuse-import-status-icon
        *ngIf="collapsed"
        [status]="importInfoService.importFinalStatus$ | async"
      ></fuse-import-status-icon>
    </div>
    <div class="header__buttons">
      <button
        mat-icon-button
        [disabled]="isLoading"
        (click)="onToggle()"
      >
        <mat-icon
          *ngIf="collapsed"
          class="arrow-icon"
        >keyboard_arrow_up</mat-icon>
        <mat-icon
          *ngIf="!collapsed"
          class="arrow-icon"
        >keyboard_arrow_down</mat-icon>
      </button>
      <button
        mat-icon-button
        [disabled]="isLoading"
        (click)="onClose()"
      >
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div
    class="import-info__body"
    [class.collapsed]="collapsed"
  >
    <div class="imports-list">
      <ng-container *ngIf="isLoading && isEmptyList()">
        <div class="grey">
          Loading...
        </div>
      </ng-container>
      <ng-container *ngIf="!isEmptyList()">
        <table class="import-table">
          <tr *ngFor="let importItem of importList$ | async">
            <td style="width: 24px">
              <button
                *ngIf="importItem.expandable"
                mat-icon-button
                [disabled]="isLoading"
                (click)="toggleType(importItem.typeId)"
              >
                <mat-icon
                  *ngIf="(openedTypeId$ | async) === importItem.typeId"
                  class="arrow-icon"
                >keyboard_arrow_down</mat-icon>
                <mat-icon
                  *ngIf="(openedTypeId$ | async) !== importItem.typeId"
                  class="arrow-icon"
                >keyboard_arrow_right</mat-icon>
              </button>
            </td>
            <td>
              <span
                class="cursor-pointer"
                (click)="onClickImportItem($event, importItem)"
                [ngClass]="{
                  'old-import': !importItem.latest,
                  'outline': !importItem.expandable
                }"
              >
                {{ importItem.latest ? typeNames[importItem.typeId] : '' }}
                <ng-container *ngIf="importItem.latest">{{ importItem.endDate | date : 'shortDate' }}</ng-container>
                <ng-container *ngIf="!importItem.latest">{{ importItem.endDate | date : 'HH:mm:ss' }}</ng-container>
              </span>
            </td>
            <td>
              <span>{{importItem.importedEntities}}/{{importItem.totalEntities}}</span>
            </td>
            <fuse-import-status-icon
              [status]="importItem.statusId"
              [imported]="importItem.importedEntities"
            ></fuse-import-status-icon>
          </tr>
        </table>
      </ng-container>
      <ng-container *ngIf="!isLoading && isEmptyList()">
        <div class="grey">
          No records
        </div>
      </ng-container>
    </div>
  </div>
</div>

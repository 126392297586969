<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button
        *ngIf="!noNav"
        mat-button
        class="toggle-button-navbar mat-icon-button"
        (click)="toggleSidebarOpened('navbar')"
        fxHide.gt-md
      ><mat-icon>menu</mat-icon></button>

      <div class="toolbar-separator" fxHide.gt-md></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNav">
        <div class="logo ml-16">
          <img class="logo-icon" src="assets/branding/default/logo.png" onerror="this.onerror=null; this.src='assets/branding/logo.svg'">
        </div>
      </div>
    </div>

    <ng-container *ngIf="isExtendedNavigationElement && isNewLiveMap">
      <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center" class="center-buttons">
        <a class="nav-link live-map" [routerLink]="'/app/live-map/planning'" routerLinkActive="active">
          <span>Planning mode</span>
        </a>
<!--        <a class="nav-link live-map" [routerLink]="'/app/live-map/live'" routerLinkActive="active">-->
<!--          <span style="margin-left: 10px">Live mode</span>-->
<!--        </a>-->
        <a class="nav-link live-map disabled" (click)="onComingSoon()">
          <span style="margin-left: 10px">Live mode</span>
        </a>
      </div>
    </ng-container>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center" style="gap: 8px">
      <ng-content select="button"></ng-content>
<!--      <button-->
<!--        class="header-action-button"-->
<!--        mat-icon-button-->
<!--        matTooltip="Send message to driver"-->
<!--        (click)="sendMessageToVehicleClick()"-->
<!--      ><mat-icon fontSet="material-icons-outlined">perm_phone_msg</mat-icon></button>-->

      <div class="toolbar-separator"></div>

      <button
        mat-button
        class="user-button"
        [matMenuTriggerFor]="userMenu"
      >
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="avatar" src="assets/images/avatars/profile.jpg">
          <span class="username mr-12" fxHide fxShow.gt-sm>{{loggedInUser}}</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <button
          mat-menu-item
          class="toolbar-menu-button"
          [routerLink]="accountLink()"
        >
          <span>{{'Account: ' + sharedService.accountName}}</span>
        </button>

        <button
          *ngIf="workspacePlansService.isAvailableModule()"
          mat-menu-item
          class="toolbar-menu-button"
          [routerLink]="plansLink()"
        ><span>Plans</span></button>

        <button
          mat-menu-item
          class="toolbar-menu-button"
          (click)="logOut()"
        ><span>Logout</span></button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>

<div class="intro-view">
  <mat-progress-bar
    class="loading-bar"
    *ngIf="isApiLoading"
    color="accent"
    mode="indeterminate"
  ></mat-progress-bar>

  <fuse-app-intro-sidebar></fuse-app-intro-sidebar>
  <div class="intro-form-wrapper">
    <div class="form">
      <div class="logo">
        <img
          src="assets/branding/default/logo.png"
          onerror="this.onerror=null; this.src='assets/branding/logo.svg'"
        />
      </div>

      <ng-container *ngIf="isReady && !onboardingCompleted">
        <div class="stepper-indicators">
          <div
            *ngFor="let step of stepsList; index as i"
            class="indicator"
            [ngClass]="getIndicatorClass(step)"
          >
            <span>{{ i + 1 }}</span>
          </div>
        </div>

        <fuse-app-company-info *ngIf="currentStep === steps.CompanyInfo"></fuse-app-company-info>

        <fuse-app-add-vehicles *ngIf="currentStep === steps.Vehicles"></fuse-app-add-vehicles>

        <fuse-app-add-drivers *ngIf="currentStep === steps.Drivers"></fuse-app-add-drivers>
      </ng-container>
    </div>

    <div
      *ngIf="onboardingCompleted"
      class="onboarding-congratulations"
      [@animate]="{ value: '*', params: { x: '100%' } }"
    >
      <mat-icon
        [svgIcon]="checkIcon"
        class="green giant-icon"
      ></mat-icon>

      <div class="title">Congratulations!</div>

      <div class="sub-title">Welcome to SolBox.</div>

      <div class="submit-buttons">
        <button
          mat-raised-button
          color="accent"
          (click)="finish()"
        >
          Start Now
        </button>
      </div>

      <div class="qr-block">
        <div class="qr-list">
          <div class="qr-item">
            <div class="qr-title">Driver App Download</div>
            <img
              class="qr-code"
              src="assets/images/qr-codes/app-qr.png"
            />
          </div>
        </div>
        <div>
          <a
            href="https://me-qr.com/PJry1w"
            target="_blank"
            >Click here</a
          >&nbsp;to download the Driver App.
        </div>
      </div>
    </div>
  </div>
</div>

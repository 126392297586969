import { Moment } from 'moment/moment';
import { EManifestStatuses, EOrderStatuses, EOrderTypes } from 'app/consts/enums';
import { timeStamp } from '../../models/interfaces';

export interface IUnassignedOrder {
  orderId: number;
  orderTypeId: EOrderTypes;
  orderTypeName: string;
  orderStatusId: EOrderStatuses;
  orderStatusName: string;

  deliveryAddress: string | null;
  deliveryClientId: number | null;
  deliveryClientName: string;
  deliveryLat: number | null;
  deliveryLong: number | null;
  deliveryTime: string;
  deliveryTolerance: number;
  deliveryServiceTime: number;

  pickupAddress: string | null;
  pickupClientId: number | null;
  pickupClientName: string;
  pickupLat: number | null;
  pickupLong: number | null;
  pickupTime: string;
  pickupTolerance: number;
  pickupServiceTime: number;

  isPickup: 0 | 1;
  onTime: -1 | 0 | 1; // -1 - earlier; 0 - on time; 1 - later
  IsFutile: boolean;

  customerReference: string | null;
  capacityUnits: string;
  capacityName: string;
  orderTotalCapacity: number | null;
  orderMaxDimension: number | null;
  attributes: number[] | null;
  length: number | null;
  price: number | null;
  volume: number | null;
}

export interface ILiveMapManifestOrder {
  orderId: number;
  orderTypeId: EOrderTypes;
  orderTypeName: string;
  orderStatusId: EOrderStatuses;
  orderStatusName: string;

  deliveryAddress: string | null;
  deliveryClientId: number | null;
  deliveryClientName: string;
  deliveryLat: number | null;
  deliveryLong: number | null;
  deliveryTime: string;
  deliveryTolerance: number;

  pickupAddress: string | null;
  pickupClientId: number | null;
  pickupClientName: string;
  pickupLat: number | null;
  pickupLong: number | null;
  pickupTime: string;
  pickupTolerance: number;

  customerReference: string | null;
  capacityUnits: string;
  capacityName: string;
  orderTotalCapacity: number | null;
  orderMaxDimension: number | null;
  attributes: number[] | null;
  length: number | null;
  price: number | null;
  volume: number | null;

  deliveryServiceTime: number;
  pickupServiceTime: number;
  deliveryComment: string;
  pickupComment: string;
  startTime: timeStamp;
  travelTime: number; // in seconds
  serviceTime: number; // in seconds
  linkId: number;
  isPickup: 0 | 1;
  IsFutile: boolean;
  onTime: -1 | 0 | 1; // -1 - earlier; 0 - on time; 1 - later
  earliestArrival: timeStamp;
}

export type LiveMapOrder = IUnassignedOrder | ILiveMapManifestOrder;

export interface ILiveMapManifest {
  id: number;
  name: string;
  driverId: number;
  driverFullName: string;
  vehicleId: number;
  vehicleName: string;
  date: timeStamp;
  orders: ILiveMapManifestOrder[];
  startLat: number | null;
  startLng: number | null;
  endLat: number | null;
  endLng: number | null;
  routeDistance: number|null;
  routeMinutes: number|null;
  isActive: boolean;
  status: EManifestStatuses;
}

export interface ILiveMapManifestCreate {
  name: string;
  driverId: number;
  vehicleId: number;
  date?: timeStamp;
  orderIds: number[];
}

export type OrdersFilter = Record<EOrderTypes, boolean>;
export type ManifestsFilter = Record<EManifestStatuses, boolean>;

export interface ILiveMapFilter {
  orders?: OrdersFilter;
  manifests?: ManifestsFilter;
  date: Moment;
  showOrdersWithoutDate: boolean;
}

export interface ILiveMapFilterStorage {
  orders?: OrdersFilter;
  manifests?: ManifestsFilter;
  date: string;
  showOrdersWithoutDate: boolean;
}

export interface ILiveMapFilterEvent {
  current: ILiveMapFilter;
  previous: ILiveMapFilter;
}

export interface ILiveMapManifestToReordering {
  id: number;
  orders: { orderId?: number, linkId?: number }[];
}

export interface ILiveMapManifestUpdate {
  Manifests: { ManifestId: number, OrdersManifests: { LinkId?: number, OrderId?: number }[] }[];
}

export interface IUnassignedOrdersSelector {
  selectedOrderIds: number[];
  ordersIdsToSaving: number[];
  isDragging: boolean;
}

export interface ILiveMapVehicleGetDTO {
  Id: number;
  Name: string;
  Lat: number;
  Lng: number;
  LastOnline: timeStamp;
}

export interface ILiveMapVehicle {
  id: number;
  name: string;
  lat: number;
  lng: number;
  lastOnline: timeStamp;
  assignedManifests: { id: number; name: string; }[];
}

export enum EVehicleEnrouteStatus {
  None = 'none',
  Enroute = 'enroute',
}


export enum EVehicleOnlineStatus {
  Online = 'online',
  Offline = 'offline',
}

export interface UnlinkOrderResult {
  unlinkedOrder: boolean;
  removedManifest: boolean;
}

export interface ISummaryCounter {
  orders: {
    pending: number,
    enroute: number,
    completed: number,
    cancelled: number,
    delayed: number,
  };
  manifests: {
    completed: number,
  };
  drivers: {
    lessThanFiveMins: number,
    lessThanEightHours: number,
    moreThanEightHours: number,
  };
}

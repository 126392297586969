<ng-container *ngIf="layout === 'vertical'" data-testid="navbar-vertical-container">
  <div class="navbar-vertical" fusePerfectScrollbar data-testid="navbar-vertical">
    <div class="navbar-header" data-testid="navbar-header">
      <div class="logo branding" data-testid="navbar-logo">
        <img
          class="logo-icon"
          src="assets/branding/default/logo.png"
          onerror="this.onerror=null; this.src='assets/branding/logo.svg'"
          data-testid="navbar-logo-img"
        >
      </div>
      <button
        mat-button
        fxHide.lt-lg
        class="toggle-button-navbar mat-icon-button"
        (click)="toggleSidebarFolded('navbar')"
        data-testid="navbar-toggle-fold-button"
      ><mat-icon data-testid="navbar-menu-icon">menu</mat-icon></button>
      <button
        mat-button
        fxHide.gt-md
        class="toggle-button-navbar mat-icon-button"
        (click)="toggleSidebarOpened('navbar')"
        data-testid="navbar-toggle-open-button"
      ><mat-icon data-testid="navbar-back-icon">arrow_back</mat-icon></button>
    </div>

    <div class="navbar-content" fusePerfectScrollbar data-testid="navbar-content">
      <fuse-navigation [navigation]="sharedService.navigation" layout="vertical" data-testid="navbar-navigation-vertical"></fuse-navigation>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="layout === 'horizontal'" data-testid="navbar-horizontal-container">
  <div class="navbar-horizontal" data-testid="navbar-horizontal">
    <fuse-navigation [navigation]="sharedService.navigation" layout="horizontal" data-testid="navbar-navigation-horizontal"></fuse-navigation>
  </div>
</ng-container>

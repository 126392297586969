<div class="imports-icon">
  <mat-icon
    *ngIf="!isProgress()"
    [svgIcon]="icon.icon"
    [class]="icon.color"
    [matTooltip]="icon.text"
  ></mat-icon>
  <mat-spinner
    *ngIf="isProgress()"
    [diameter]="20"
  ></mat-spinner>
</div>
import { Component, OnInit } from '@angular/core';
import { ImportInfoService } from '../import-info.service';
import { map } from 'rxjs/operators';
import { IImportHistory } from 'app/models/interfaces';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { SharedService } from 'app/services/shared.service';
import { EImportStatus } from 'app/consts/enums';

type ImportHistoryViewItem = IImportHistory & { latest?: boolean, expandable?: boolean };

@Component({
  selector: 'fuse-import-info',
  templateUrl: './import-info.component.html',
  styleUrls: ['./import-info.component.scss']
})
export class ImportInfoComponent implements OnInit {
  readonly typeNames = typeNames;

  get isVisible(): boolean {
    return this.importInfoService.isVisible;
  }
  get collapsed(): boolean {
    return this.importInfoService.collapsed;
  }
  set collapsed(value: boolean) {
    this.importInfoService.collapsed = value;
  }
  get isLoading(): boolean {
    return this.importInfoService.isLoading;
  }

  readonly openedTypeId$ = new BehaviorSubject<number | null>(null);

  readonly importList$ = combineLatest(
    [this.openedTypeId$, this.importInfoService.importList$]
  )
    .pipe(
      map(([openedTypeId, list]) => {
        if (!list?.length) return [];
        const types = new Set<number>();
        const result = new Array<ImportHistoryViewItem>();
        for (let i of list) {
          if (!types.has(i.typeId)) {
            result.push({ ...i, latest: true, expandable: list.some(l => l !== i && l.typeId == i.typeId) });
            types.add(i.typeId);
          } else if (openedTypeId === i.typeId) {
            result.push(i);
          }
          if (openedTypeId === i.typeId && result.some((l) => l.id === i.id && l.expandable)) {
            result.push(i);
          }
        }
        return result;
      })
    );

  constructor(
    public importInfoService: ImportInfoService,
    private sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this.importInfoService.fetchImportHistoryByTimer();
  }

  recap(item: IImportHistory) {
    if (item.importedEntities && item.totalEntities) {
      return item.importedEntities + "/" + item.totalEntities;
    }
    return null;
  }

  toggleType(typeId: number) {
    if (this.openedTypeId$.value === typeId) {
      this.openedTypeId$.next(null);
    }
    else {
      this.openedTypeId$.next(typeId);
    }
  }

  public onToggle = (): void => {
    this.collapsed = !this.collapsed;
  }

  public onClose = (): void => {
    this.importInfoService.isVisible = false;
  }

  public isEmptyList = (): boolean => {
    return this.importInfoService.getImportsList().length === 0;
  }

  public onClickImportItem = (event: MouseEvent, entry: ImportHistoryViewItem): void => {
    event.stopPropagation();
    if (entry.expandable) {
      this.toggleType(entry.typeId);
    } else {
      this.sharedService.showServerMessageStreamDialog({
        asyncImportId: entry.id,
        asyncFinished: entry.statusId !== EImportStatus.Process,
        title: entry.typeName
      }, () => { });
    }
  }
}


const typeNames = {
  [0]: 'none',
  [1]: 'Mix (Vehicle)',
  [2]: 'Fresho',
  [3]: 'Safety Items',
  [4]: 'Mix (Orders)',
  [5]: 'Vehicles',
  [6]: 'MYOB',
  [7]: 'OrderMentum',
  [8]: 'Orders',
  [9]: 'Customers',
  [10]: 'Jiwa',
  [11]: 'CSV (Orders)',
  [12]: 'XLS (Orders)',
  [13]: 'Shopify',
  [14]: 'Woodwards',
  [15]: 'Acreedo',
  [16]: 'Dynamics',
  [17]: 'WooCommerce',
  [18]: 'Mix (Vehicles)',
  [19]: 'XLS (Vehicles)',
  [20]: 'Order with items'
}

import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { BehaviorSubject, Subject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { IAccountConfigFull } from "app/models/interfaces";

@Injectable({ providedIn: 'root' })
export class AccountConfigService {
    private readonly _config$ = new BehaviorSubject<IAccountConfigFull | null>(null);

    private configGetting: Promise<IAccountConfigFull>;

    constructor(private api: ApiService) {
    }

    //** Returns current config and every further updates */
    get config$() {
        this.init();
        return this._config$.pipe(filter(t => Boolean(t)));
    }

    //** Call only one time for actual config */
    async getConfig(observer: (c: IAccountConfigFull) => void) {
        this.init();
        observer(await this.configGetting);
    }

    logout() {
        this.configGetting = null
        this._config$.next(null);
    }

    reload() {
        if (this.configGetting) {
            this.configGetting = null;
            this.init();
        }
    }

    private async init() {
        if (!this.configGetting) {
            this.configGetting = this.api.getConfigFull()
                .pipe(map(r => r.body))
                .toPromise();
            const c = await this.configGetting;
            this._config$.next(c);
        }
    }
}